import { gsap } from "gsap";
import { FC, useEffect, useRef } from "react";

interface IProps {
  children: any;
  customStyle?: string;
  play?: boolean;
};

const Pulser: FC<IProps> = ({ customStyle = '', play = true, children }) => {
  const ref: any = useRef(null);

  useEffect(() => {
    let tween: any | null = null;

    if (play && ref) {
      const element: HTMLElement = ref.current;
      tween = gsap.timeline({ delay: 2, repeat: -1, repeatDelay: 2 });

      tween.fromTo(
        element,
        {
          scale: 1
        },
        {
          scale: 1.1,
          duration: 0.3,
          ease: "power2.in",
          repeat: 3,
          yoyo: true
        }
      );
    }

    return () => {
      if (tween) {
        tween.kill();
      }
    };
  }, [ref, play]);

  return (
    <div ref={ref} style={{ width: 'fit-content' }} className={customStyle}>
      {children}
    </div>
  )
};

export default Pulser;
