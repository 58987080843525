import classNames from 'classnames';
import aboutStyle from '../../../css/pages/about.module.scss';
import style from '../../../css/pages/about/plans.module.scss';
import pageStyle from "../../../css/pages/page.module.scss";

const Plans = () => {
  const list: { title: string, desc: string }[] = [
    { title: "COD3 ZON3 - for kids", desc: `Something in my sights for when time permits, I'd like to offer short, fun classes, aimed at teaching local, young kids how to venture into the world of website development. A simple course would guide them through the basics of HTML, CSS and hosting, allowing them to show off their achievements to their family and friends. More advanced classes would explore JavaScript, NPM and React.js.` },
    { title: "DevOps", desc: `Already understanding much about DevOps, touching on it in several of my commercial and personal developments, I wish to progress further within this area. Initially looking at Jenkins, GitHub Actions and Unit Testing, I ultimately intend to work with Amazon Web Services and Google Cloud Platform.` },
    { title: "eCommerce", desc: `Being something which I haven't yet had the opportunity to be involved in, I recognise its importance and am keen to gain some experience within this area.` },
    { title: "Metaverse", desc: `I have an interest in how the Metaverse will affect, enhance and disrupt websites, as we know them today. Will they remain two separate entities or will the Metaverse end up consuming websites, offering them up in a new virtual world? For developers, how will this change things - or won't it?` },
    { title: "Node.js", desc: `Since benefitting from a 'Node.js Application Development' course, I'm now embarking on a 'Node.js Services Development' course, which includes the use of Express.js and RESTful services.` },
    { title: "Docker, MySQL and PHP", desc: `Having recently worked commercially with Docker, MySQL and PHP, I look to implement and expand on these in my personal learnings and developments.` },
    { title: "Vue.js", desc: `Not wishing to be pigeon-holed to the use of the React.js library - even though I love it - I've experimented with the Vue framework but intend to gain much more exposure to it.` }
  ];

  const contentBlockStyle = classNames({
    [pageStyle.contentBlock]: true,
    [aboutStyle.contentBlock]: true
  });

  const renderList = () => {
    return list.map((item, index) => {
      return (
        <div className={style.item} key={index}>
          <p className={style.title}>{item.title}</p>
          <p>{item.desc}</p>
        </div>
      )
    });
  };

  return (
    <div className={contentBlockStyle}>
      <h2>Goals</h2>
      <p>As well as planning and developing projects, I also enjoy planning and developing my own goals and skills. Here are some of the things which are currently on my radar.</p>
      {renderList()}
    </div>
  )
};

export default Plans;