// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth, signInWithEmailAndPassword, signOut, User } from "firebase/auth";
import { child, Database, get, getDatabase, ref } from "firebase/database";
import { ERROR_CODES } from "./statics";

// Your web app's Firebase configuration
const firebaseConfigProduction: object = {
  apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_PROD_FIREBASE_DATABASE_URL
};

const firebaseConfigStaging: object = {
  apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASE_URL
};

// Initialize Firebase
let app: FirebaseApp;

if (process.env.REACT_APP_RELEASE_TYPE === 'PRODUCTION') {
  app = initializeApp(firebaseConfigProduction);
} else {
  app = initializeApp(firebaseConfigStaging);
}

const auth: Auth = getAuth();
let user: User | null = null;
let checkingUser: boolean = true;

// get logged in user on start up
auth.onAuthStateChanged((_user: User | null): void => {
  if (_user) user = _user;
  else user = null;

  checkingUser = false;
})

// Initialize the database
const db: Database = getDatabase(app);

const logIn = async (email: string, password: string): Promise<User> => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => resolve(userCredential.user))
      .catch((error) => {
        let e: string = ERROR_CODES.UNKNOWN;
        if (
          error.message.indexOf('invalid-email') !== -1 ||
          error.message.indexOf('user-not-found') !== -1
        ) {
          e = ERROR_CODES.INCORRECT_EMAIL;
        } else if (error.message.indexOf('wrong-password') !== -1) {
          e = ERROR_CODES.INCORRECT_PASSWORD;
        } else if (error.message.indexOf('too-many-requests') !== -1) {
          e = ERROR_CODES.TOO_MANY_TRIES;
        }

        reject(e)
      });
  });
}

const logOut = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => resolve())
      .catch((error) => reject(error.message));
  })
};

/**
 * Returns the current Firebase user or null.
 */
const getUser = async (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    // Poll in case requested on start up.
    const check: any = setInterval(() => {
      if (!checkingUser) {
        clearInterval(check);
        resolve(user);
      }
    }, 100);
  });
};

const getPageDBData = async (pageId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    get(child(ref(db), `pages/${pageId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { db, logIn, logOut, getUser, getPageDBData };

