import { combineReducers, createStore, compose } from 'redux';
import { settings } from './reducers';
//ref: https://www.mydatahack.com/getting-redux-devtools-to-work-with-typescript/
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const rootReducer = combineReducers({ settings })

    return createStore(rootReducer, composeEnhancers());
}

export default configureStore;