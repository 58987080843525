import { FC } from "react";
import style from '../../css/pages/contact.module.scss';
import pageStyle from "../../css/pages/page.module.scss";
import { getInnerWrapperStyle, getOuterWrapperStyle } from "../../js/style-utils";
import EmailForm from "../common/email-form/EmailForm";
import TransitionIn from "../common/ui/TransitionIn";

interface IProps {
  first?: boolean;
  colorIndex: number;
};

const Contact: FC<IProps> = ({ first = false, colorIndex }) => {
  const outerWrapperStyle = getOuterWrapperStyle(first, colorIndex);
  const innerWrapperStyle = getInnerWrapperStyle(first);

  return (
    <>
      <a id="contact" href="_"> </a>
      <div className={outerWrapperStyle}>
        <div className={pageStyle.wrapper}>
          <div className={innerWrapperStyle}>
            <TransitionIn>
              <h1>contact.</h1>
            </TransitionIn>
            <p>Please feel free to give me a call or send me a message. If I miss your call, I'm sure to come back to you as soon as I can.</p>
            <h2 className={style.title}>Telephone</h2>
            <a href="tel:07899997508" className={style.tel}><p>07899 997508</p></a>
            <EmailForm />
          </div>
        </div>
      </div>
    </>
  )
};

export default Contact;