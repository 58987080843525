import { FC } from 'react';
import style from '../../../css/common/skills-list.module.scss';
import SkillsBar from "./SkillsBar";

interface IProps {
  disabled?: boolean;
}

const SkillsList: FC<IProps> = ({ disabled = false }) => {
  const getBarId = () => {
    return `bar_${Math.floor(Math.random() * 1000000)}`;
  };

  return (
    <>
      <SkillsBar disabled={disabled} label="Agile" perc={60} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="AWS" perc={20} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Bootstrap" perc={65} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="CSS" perc={95} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="DevOps" perc={50} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Docker" perc={20} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Emotion" perc={70} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Express JS" perc={25} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Git" perc={65} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="HTML" perc={85} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="JavaScript" perc={90} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Jest" perc={35} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="MySQL" perc={35} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Next JS" perc={60} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Node JS" perc={40} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="NPM" perc={75} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="OOP" perc={65} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="PhotoShop" perc={80} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="PHP" perc={35} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="React JS" perc={85} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Redux" perc={50} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Sass" perc={80} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="SEO" perc={65} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Twig" perc={30} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="TypeScript" perc={65} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="UI/UX" perc={65} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="Vue JS" perc={40} barId={getBarId()} />
      <SkillsBar disabled={disabled} label="WordPress" perc={40} barId={getBarId()} />
      <p className={style.footNote}>OOP = Object Orientated Programming</p>
      <p className={style.footNote}>SEO = Search Engine Optimisation</p>
    </>
  )
}

export default SkillsList;