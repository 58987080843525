import classNames from "classnames";
import pageStyle from "../css/pages/page.module.scss";

/**
 * 
 * @param first boolean - Whether the first page in a group.
 * @param colorIndex number - Optional. Colour index to use. Default: no colour.
 * @returns 
 */
const getOuterWrapperStyle = (first: boolean = true, colorIndex: number = -1): any => {
  return classNames({
    [pageStyle.outerWrapper]: true,
    [pageStyle.outerWrapperFirst]: first,
    [pageStyle.color1]: colorIndex !== -1 && colorIndex === 1,
    [pageStyle.color2]: colorIndex !== -1 && colorIndex === 2
  });
};

const getInnerWrapperStyle = (first: boolean = true): any => {
  return classNames({
    [pageStyle.innerWrapper]: true,
    [pageStyle.innerWrapperFirst]: first
  });
};

export { getOuterWrapperStyle, getInnerWrapperStyle };

