import classNames from 'classnames';
import { FC } from 'react';
import style from '../../../css/common/ui/screen-tint.module.scss';
import SubNavDispatcher from '../../../js/sub-nav-dispatcher';

interface IProps {
  wide: boolean;
  setShowTintWide?: Function;
  setShowTintNarrow?: Function;
  setOpenNavNarrow?: Function;
  subNavDispatcher?: SubNavDispatcher;
};

const NavScreenTint: FC<IProps> = ({ wide, setShowTintWide, setShowTintNarrow, setOpenNavNarrow, subNavDispatcher }) => {
  const tintStyle = classNames({
    [style.wrapper]: true,
    [style.wide]: wide,
    [style.narrow]: !wide
  });

  return <div className={tintStyle} onClick={() => {
    if (setShowTintWide) {
      setShowTintWide(false);
      if (subNavDispatcher) {
        subNavDispatcher.emit(SubNavDispatcher.WIDE_TINT_CLICKED);
      }
    }

    if (setShowTintNarrow) {
      setShowTintNarrow(false);
      if (setOpenNavNarrow) setOpenNavNarrow(false);
    }
  }}></div>
};

export default NavScreenTint;