import { FC, ReactElement, useEffect, useState } from "react";
import style from '../../css/pages/page-group.module.scss';
import SubNavDispatcher from "../../js/sub-nav-dispatcher";

interface IProps {
  children: any;
  subNavDispatcher: SubNavDispatcher;
};

const PageGroup: FC<IProps> = ({ children, subNavDispatcher }): ReactElement => {
  const [url, setUrl] = useState<string | null>(null);
  const [scrollY, setScrollY] = useState<number>(0);

  /**
  * Toggles the fixed state of the pageGroup when the nav or sub nav is opened and closed.
  */
  useEffect(() => {
    const openNav = (omitPageFix: boolean = false) => {
      if (!omitPageFix) {
        const element: HTMLElement | null = document.getElementById('pageGroup');

        if (element) {
          setScrollY(window.pageYOffset);

          // Set the page position to the same as the scroll position, then fix it.
          element.style.top = `-${window.pageYOffset}px`;
          element.style.position = 'fixed';

          // Set style in preparation for window.scrollTo, used when closing.
          document.documentElement.style.scrollBehavior = "auto";
        }
      }
    };

    const closeNav = () => {
      const element: HTMLElement | null = document.getElementById('pageGroup');

      if (element) {
        // Remove the fixed page position and reset the scroll back to what it was
        // before opening the navigation.
        if (window.getComputedStyle(element).position === 'fixed') {
          element.style.position = 'unset';
          window.scrollTo({ top: scrollY });

          // Reset the scroll behavior for smooth scrolling.
          // On non-ios, it tends to work without this, however, not when using
          // the browser back button. This 'smooth' behaviour fixes that.
          // Note that smooth scrolling doesn't work in Safari but there are some
          // JavaScript polyfills, for example: https://github.com/iamdustan/smoothscroll
          document.documentElement.style.scrollBehavior = "smooth";
        }
      }
    }

    const openNavHandler = (omitPageFix: boolean = false) => { openNav(omitPageFix) };

    if (subNavDispatcher) {
      subNavDispatcher.on(SubNavDispatcher.OPEN_MAIN_NARROW, openNav);
      subNavDispatcher.on(SubNavDispatcher.CLOSE_MAIN_NARROW, closeNav);
      subNavDispatcher.on(SubNavDispatcher.OPEN_SUB_WIDE, openNavHandler);
      subNavDispatcher.on(SubNavDispatcher.CLOSE_SUB_WIDE, closeNav);
      subNavDispatcher.on(SubNavDispatcher.WIDE_TINT_CLICKED, closeNav);
    }

    return () => {
      if (subNavDispatcher) {
        subNavDispatcher.off(SubNavDispatcher.OPEN_MAIN_NARROW, openNav);
        subNavDispatcher.off(SubNavDispatcher.CLOSE_MAIN_NARROW, closeNav);
        subNavDispatcher.off(SubNavDispatcher.OPEN_SUB_WIDE, openNavHandler);
        subNavDispatcher.off(SubNavDispatcher.CLOSE_SUB_WIDE, closeNav);
        subNavDispatcher.off(SubNavDispatcher.WIDE_TINT_CLICKED, closeNav);
      }
    };
  }, [subNavDispatcher, scrollY]);

  /**
   * Whenever the url changes, scroll to the hash anchor or the top of the page.
   */
  useEffect(() => {
    const hashParts: Array<string> = window.location.hash.split('#');

    if (hashParts.length > 1) {
      const element: HTMLElement | null = document.getElementById(hashParts[1]);

      if (element) {
        const nav: HTMLElement | null = document.getElementById('nav');
        const navHeight: number = nav ? nav.clientHeight : 0;
        window.scroll({ top: element.offsetTop - navHeight });
        return;
      }
    }

    window.scrollTo({ top: 0 });
  }, [url]);

  /**
   * Change the url state whenever the location's href changes.
   */
  useEffect(() => {
    const interval: any = setInterval(() => {
      const href: string = window.location.href;

      if (url) {
        if (url !== href) setUrl(href);
      } else {
        setUrl(href);
      }
    }, 500);

    return () => clearInterval(interval)
  }, [url]);

  return (
    <>
      <div id="screenHeightMeasurer" className={style.screenHeightMeasurer}></div>
      <div id="pageGroup" className={style.wrapper}>
        {children}
      </div>
    </>
  )
};

export default PageGroup;