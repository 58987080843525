import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import style from '../../../css/common/showcase/grid-item.module.scss';
import { isTouchDevice } from '../../../js/utils';
import GridItemOptions from './GridItemOptions';
import GridItemTitle from './GridItemTitle';

interface IProps {
  selectedCallback: Function; // Touch use only.
  itemSelected: boolean; // Touch use only.
  hoverCallback: Function; // Pointer use only.
  itemHover: boolean; // Pointer use only.
  index: number;
  openImageCallback: Function;
  data: any;
  projectType: string;
};

const GridItem: FC<IProps> = ({
  index,
  selectedCallback,
  itemSelected,
  hoverCallback,
  itemHover,
  openImageCallback,
  data,
  projectType
}) => {
  const [hover, setHover] = useState(false);
  const [touchSelected, setTouchSelected] = useState(false);
  let exceededYMove: boolean = false;
  let startY: number = 0;
  let yMoveThreshold: number = 20;

  const getYFromEvent = (e: any): number => {
    if (e.touches && e.touches.length > 0) {
      return e.touches[0].clientY;
    }

    return 0;
  };

  const bannerStyle = classNames({
    [style.banner]: true,
    [style.bannerPrototype]: data.prototype,
    [style.bannerReplica]: data.replica,
    [style.bannerGame]: data.game,
    [style.bannerDesign]: data.design
  });

  const wrapperStyle = classNames({
    [style.wrapper]: true,
    [style.wrapperHover]: hover,
    [style.wrapperTouch]: touchSelected
  });

  const clickableAreaStyle = classNames({
    [style.clickableArea]: true,
    [style.disable]: touchSelected,
    [style.clickableAreaHover]: hover,
    [style.clickableAreaTouch]: touchSelected
  });

  // pointerAnimateIn is applied only for pointer devices; for touch devices,
  // we use touchAnimateIn.
  const titleStyle = classNames({
    [style.pointerTitleAnimateIn]: hover,
    [style.touchTitleAnimateIn]: touchSelected
  });

  // pointerAnimateIn is applied only for pointer devices; for touch devices,
  // we use touchAnimateIn.
  const optionsStyle = classNames({
    [style.pointerOptionsAnimateIn]: hover,
    [style.touchOptionsAnimateIn]: touchSelected
  });

  const leftSpacerStyle = classNames({
    [style.leftSpacer]: index % 2
  });

  const rightSpacerStyle = classNames({
    [style.rightSpacer]: !(index % 2)
  });

  const onTouchStart = (e: any) => {
    if (!touchSelected) {
      exceededYMove = false;
      startY = getYFromEvent(e);
    }
  };

  const onTouchMove = (e: any) => {
    if (!touchSelected) {
      const y: number = getYFromEvent(e);
      const greatest: number = Math.max(y, startY);
      const smallest: number = Math.min(y, startY);
      const diff: number = greatest - smallest;
      if (diff > yMoveThreshold) exceededYMove = true;
    }
  };

  const onTouchEnd = () => {
    if (!touchSelected && !exceededYMove) {
      // inform all others to close
      // all component then get itemSelected = true, which is picked up by useEffect
      selectedCallback();

      // set this one to open - next few ticks
      setTimeout(() => setTouchSelected(true), 10);
    }
  };

  const onHover = () => {
    if (!hover) {
      // inform all others to close
      // all component then get itemHover = true, which is picked up by useEffect
      hoverCallback();

      // set this one to open - next two ticks (1 wasn't enough for macOS 10.13, Safari 11)
      setTimeout(() => setHover(true), 2);
    }
  };

  const onOpenImage = () => {
    openImageCallback(index);
  };

  // Resets touch selected state for all items.
  useEffect(() => {
    if (itemSelected) {
      setTouchSelected(false);
    }
  }, [itemSelected])

  // Resets pointer hover state for all items.
  useEffect(() => {
    if (itemHover) {
      setHover(false);
    }
  }, [itemHover])

  return (
    <div className={style.outerWrapper}>
      <div className={leftSpacerStyle}></div>
      <div className={style.borderWrapper}>
        <div className={bannerStyle}></div>
        <div
          className={wrapperStyle}
          style={{ backgroundImage: `url(${data.imageSmall})` }}
        >
          <div
            className={clickableAreaStyle}
            onMouseEnter={() => {
              if (!isTouchDevice()) onHover()
            }}
            onTouchStart={(e) => onTouchStart(e)}
            onTouchMove={(e) => onTouchMove(e)}
            onTouchEnd={onTouchEnd}
          ></div>
          <GridItemTitle classname={titleStyle} data={data} />
          <GridItemOptions classname={optionsStyle} projectType={projectType} data={data} openImageCallback={onOpenImage} />
        </div>
      </div>
      <div className={rightSpacerStyle}></div>
    </div>
  )
}

export default GridItem;