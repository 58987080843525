import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FC, useEffect, useRef, useState } from 'react';
import style from '../../../css/common/ui/scroll-arrow.module.scss';
import { getScreenHeight, isPortrait } from "../../../js/utils";

interface IProps {
  play?: boolean;
  onClick?: Function;
};

const ScrollArrow: FC<IProps> = ({ play = true, onClick }) => {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef<HTMLDivElement | null>(null);
  // y position where the scrolling will begin.
  const [animStart, setAnimStart] = useState(0);
  // Whether the set of animations have been informed to play.
  const [scrollDone, setScrollDone] = useState(false);
  const [scrollFadedOut, setScrollFadedOut] = useState(false);

  // RESIZE
  //
  // Sets the screen height and changes on screen rotation.
  useEffect(() => {
    const resizeHandler = () => setAnimStart(getScreenHeight() * 0.5);
    window.addEventListener("resize", resizeHandler, false);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  // FADE ANIMATION
  //
  // Fades out the scroll arrow once scrolling has been performed.
  useEffect(() => {
    let tween: any | null = null;

    if (scrollDone && ref.current) {
      tween = gsap.to(
        ref.current,
        {
          opacity: 0,
          duration: 0.5,
          onComplete: () => setScrollFadedOut(true)
        }
      );
    }

    return () => {
      if (tween) tween.kill();
    };
  }, [scrollDone]);

  // SCROLLTRIGGER
  //
  // Ensure that the height is calculated correctly for ScrollTrigger.
  useEffect(() => {
    setTimeout(() => ScrollTrigger.refresh(), 500);
  }, []);

  // ANIMATION
  //
  useEffect(() => {
    let tween1: any | null = null;
    let tween2: any | null = null;
    // Used for scroll trigger only
    let tween3: any | null = null;

    if (ref.current) {
      const element: HTMLDivElement = ref.current;

      if (play) {
        if (!scrollDone) {
          tween1 = gsap.timeline({ delay: 1, repeat: -1, repeatDelay: 3 });

          // Bob the arrow up and down.
          tween1.fromTo(element, { marginBottom: isPortrait() ? '20vh' : '30px' },
            {
              marginBottom: isPortrait() ? 'calc(20vh - 30px)' : '0px',
              duration: 0.5,
              ease: "power1.in",
              repeat: 3,
              yoyo: true
            }
          );

          // Fade the arrow in.
          tween2 = gsap.to(element, { opacity: 1, duration: 3 });

          // Use ScrollTrigger to simply change the scrollDone state, once the user has
          // scrolled the page. The right margin tween does nothing.
          tween3 = gsap.to(element,
            {
              marginRight: '0px',
              duration: 0.1,
              onStart: () => setScrollDone(true),
              scrollTrigger: { trigger: element, start: "top " + animStart }
            }
          );
        }
      } else {
        // Fade the arrow out. Used for logging out purposes, not scrolling.
        tween2 = gsap.to(element, { opacity: 0, duration: 0.5 });
      }
    }

    return () => {
      if (tween1) tween1.kill();
      if (tween2) tween2.kill();
      if (tween3) tween3.kill();
    };
  }, [ref, play, animStart, scrollDone]);

  const onArrowClicked = () => {
    if (onClick) onClick();
  };

  return (
    <>
      {!scrollFadedOut &&
        <div className={style.wrapper} onClick={onArrowClicked}>
          <div ref={ref} className={style.arrow}></div>
        </div>}
    </>
  )
};

export default ScrollArrow;
