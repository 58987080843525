import showcaseConfig from '../data/showcase.json';

const convertRemToPixels = (rem: number): number => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

/**
 * Returns the exact height of the screen by using an element with
 * id, 'screenHeightMeasurer'.
 */
const getScreenHeight = () => {
  const shmElement = document.getElementById('screenHeightMeasurer');
  // Default height in case screenHeightMeasurer is not defined.
  let height = window.innerHeight;

  if (shmElement) {
    const style = window.getComputedStyle(shmElement).height;
    // Remove the 'px' from the end of the string.
    height = parseInt(style.substring(0, style.length - 2));
  }

  return height;
};

const getUniqueId = (prefix: string): string => {
  return `${prefix}_${Math.floor(Math.random() * 1000000)}`;
};

const getProjectConfig = (type: string, project: string): any => {
  let config: any = null;

  const typeConfig: { name: string, data: any } | undefined = showcaseConfig.find((item: any) => {
    return item.name === type;
  })

  if (typeConfig) {
    const projectConfig: { projectPage: any } | undefined = typeConfig.data.find((item: any) => {
      return item.id === project;
    })

    if (projectConfig) {
      config = projectConfig
    } else {
      console.warn(`No 'project' exists with id '${type}/${project}'.`);
    }
  } else {
    console.warn(`No 'type' exists with id '${type}'.`);
  }

  return config;
};

const getProjectPageConfig = (type: string, project: string): any => {
  let projectConfig: any = getProjectConfig(type, project);
  let pageConfig: any = null;

  if (projectConfig) {
    if (projectConfig.hasOwnProperty('projectPage')) {
      pageConfig = projectConfig.projectPage;
    } else {
      console.warn(`No 'projectPage' property exists on project '${type}/${project}'.`);
    }
  }

  return pageConfig;
};

const getProjectIds = (type: string): string[] => {
  const ids: string[] = [];

  const typeConfig: { name: string, data: any } | undefined = showcaseConfig.find((item: any) => {
    return item.name === type;
  })

  if (typeConfig) {
    for (let i = 0; i < typeConfig.data.length; i++) {
      if (typeConfig.data[i].hasOwnProperty('id')) {
        ids.push(typeConfig.data[i].id);
      } else {
        console.warn(`No 'id' property exists on data type '${type}', index ${i}.`);
      }
    }
  } else {
    console.warn(`No 'type' exists with id '${type}'.`);
  }

  return ids;
};

const isStringEmpty = (str: string) => {
  if (!str.replace(/\s/g, '').length) return true;
  return false;
};

const isTouchDevice = () => {
  // For TypeScript, set navigator to 'any' type to avoid errors
  // recognising msMaxTouchPoints.
  const nav: any = window.navigator;

  return (
    ('ontouchstart' in window) ||
    (nav.maxTouchPoints > 0) ||
    (nav.msMaxTouchPoints > 0)
  );
}

const isPortrait = () => {
  var mm = window.matchMedia("(orientation: portrait)");
  return mm.matches;
};

export {
  convertRemToPixels,
  getScreenHeight,
  getUniqueId,
  getProjectIds,
  getProjectConfig,
  getProjectPageConfig,
  isStringEmpty,
  isTouchDevice,
  isPortrait
};

