import classNames from "classnames";
import { useState } from "react";
import style from '../../../css/pages/about/intro.module.scss';
import pageStyle from "../../../css/pages/page.module.scss";
import Button from "../../common/ui/Button";
import LogoCollection from "../../common/ui/LogoCollection";
import PopInImage from "../../common/ui/PopInImage";
import Pulser from "../../common/ui/Pulser";
import TransitionIn, { SIDE } from "../../common/ui/TransitionIn";

const Intro = () => {
  const contentBlockStyle = classNames({
    [pageStyle.contentBlock]: true,
    [style.contentBlock]: true
  });

  const [cvClicked, setCvClicked] = useState<boolean>(false);

  const onCvClicked = () => {
    setCvClicked(true);
    window.open('/cv-malc-adams.pdf', '_blank');
  };

  return (
    <>
      <div className={contentBlockStyle}>
        <PopInImage src="/images/about/malc.png" alt="Malc" side={SIDE.RIGHT} />
        <div>
          <TransitionIn addDelay={0.2}>
            <Pulser play={!cvClicked}>
              <Button
                className={style.cvButton}
                label="Download my Curriculum Vitae"
                bgcolor="#20b2aa"
                bgcolorHover="#18ebe0"
                callback={onCvClicked}
              />
            </Pulser>
          </TransitionIn>
          <p>I'm a front-end developer, based in Gloucester, with over 20 years of experience in website, application and game development. I possess plenty of commercial experience, having been previously employed by two highly-rated, busy digital agencies, working for many major clients.</p>
          <p>With a wide understanding of the entire application creation process, I've been involved from project concept, through management, design and development, to testing, QA and deployment.</p>
        </div>
        <div className={pageStyle.clear}></div>
      </div>

      <LogoCollection />

      <div className={contentBlockStyle}>
        <PopInImage src="/images/about/tech-logos.png" alt="Tech" side={SIDE.LEFT} />
        <div>
          <p>My core skills lie in HTML, Sass, JavaScript (ES6), TypeScript, React.js, NPM, Git and SEO, which I enjoy using on a daily basis, creating engaging, user-facing interactions. Applications include the integration of analytics, accessibility, web services, videos and mobile ads.</p>
          <p>I look to gain a deeper experience of DevOps and Node.js, having touched on these in several of my commercial and personal developments. I recently completed a 'Node.js Application Development' course, hosted by Linux, which has given me a good grounding in the correct use of code and techniques.</p>
        </div>
        <div className={pageStyle.clear}></div>
      </div>
    </>
  )
};

export default Intro;