import { FC } from "react";
import style from '../../../css/common/nav/nav-open-button.module.scss';
import MenuIcon from '../../../images/menu-icon.png';

interface IProps {
  setOpenNavNarrow: Function;
  setShowTintNarrow: Function;
}

const NavOpenButton: FC<IProps> = ({ setOpenNavNarrow, setShowTintNarrow }) => {
  return <a className={style.button} href=" " onClick={(e) => {
    e.preventDefault();
    setOpenNavNarrow(true);
    setShowTintNarrow(true);
  }}>
    <img className={style.menuIcon} src={MenuIcon} alt='menu icon' />
  </a>
};

export default NavOpenButton;