import { Actions } from '../actions';

export const settings = (state = { showcaseCategory: null }, action) => {
    switch (action.type) {
        case Actions.SHOWCASE_CATEGORY:
            const currentState = {...state};
            currentState.showcaseCategory = action.payload;
            return currentState;
        default:
            return state;
    }
}