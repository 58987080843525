import classNames from 'classnames';
import { FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowcaseCategory } from "../../../actions";
import style from '../../../css/common/showcase/grid-item-options.module.scss';

interface IProps {
  classname: string;
  projectType: string;
  data: any;
  openImageCallback: Function;
  onSetShowcaseCategory: Function;
}

const GridItemOptions: FC<IProps> = ({ classname, projectType, data, openImageCallback, onSetShowcaseCategory }) => {
  const navigate = useNavigate();
  const wrapperStyle = classNames({ [style.wrapper]: true, [classname]: true });

  const onImage = (e: any) => {
    e.preventDefault();
    openImageCallback();
  }

  const onInfo = (e: any) => {
    e.preventDefault();
    onSetShowcaseCategory(projectType);
    navigate(`/${data.content}`);
  }

  const infoStyle = classNames({ [style.icon]: true, [style.info]: true });
  const imageStyle = classNames({ [style.icon]: true, [style.image]: true });
  const imageOption = classNames({ [style.option]: true, [style.imageOption]: true });

  return (
    <div className={wrapperStyle}>
      <a href=" " className={imageOption} onClick={(e) => onImage(e)}><div className={imageStyle}></div></a>
      <a href=" " className={style.option} onClick={(e) => onInfo(e)}><div className={infoStyle}>INFO</div></a>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetShowcaseCategory: (category: string) => dispatch(setShowcaseCategory(category)),
  }
}

export default connect(null, mapDispatchToProps)(GridItemOptions);