import classNames from 'classnames';
import aboutStyle from '../../../css/pages/about.module.scss';
import style from '../../../css/pages/about/hobbies.module.scss';
import pageStyle from "../../../css/pages/page.module.scss";

const Hobbies = () => {
  const list: { icon: string, label: string }[] = [
    { icon: "games", label: "Teacher, clown and chauffeur for my daughter." },
    { icon: "tech", label: "Tech-based projects and studies." },
    { icon: "cycling", label: "Sport: Walking, running, cycling, tennis and dancing." },
    { icon: "graph", label: "Investing and keeping up-to-date with the economy." }
  ];

  const contentBlockStyle = classNames({
    [pageStyle.contentBlock]: true,
    [aboutStyle.contentBlock]: true
  });

  const renderList = () => {
    return list.map((item, index) => {
      return (
        <div className={style.item} key={index}>
          <img src={`/images/about/hobbies/${item.icon}.png`} alt="" />
          <p>{item.label}</p>
        </div>
      )
    });
  };

  return (
    <div className={contentBlockStyle}>
      <h2>Hobbies</h2>
      <p>Aside from socialising, reading and music, here are some other activities which you'll find me doing in my spare time.</p>
      {renderList()}
    </div>
  )
};

export default Hobbies;