import { FC } from "react";
import style from '../../../css/common/ui/inset-content.module.scss';

interface IProps {
  children?: JSX.Element | Array<any>;
}

const InsetContent: FC<IProps> = ({ children }) => {
  return (
    <div className={style.wrapper}>
      {children}
    </div>
  )
}

export default InsetContent;