import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import style from '../../../css/common/login/login-error.module.scss';
import { ERROR_CODES } from '../../../js/statics';

interface IProps {
  errorCode: string | null;
  showError: boolean;
};

const LoginError: FC<IProps> = ({ showError, errorCode }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const wrapperStyle = classNames({
    [style.wrapper]: true,
    [style.wrapperShow]: showError
  });

  const getErrorMessage = (code: string): string => {
    switch (code) {
      default:
      case ERROR_CODES.UNKNOWN: return 'An unknown error has occured';
      case ERROR_CODES.TOO_MANY_TRIES: return 'Too many failed attempts - please try again later';
      case ERROR_CODES.NO_EMAIL: return 'Please enter an email address';
      case ERROR_CODES.NO_PASSWORD: return 'Please enter a password';
      case ERROR_CODES.INCORRECT_EMAIL: return 'Email address not recognised';
      case ERROR_CODES.INCORRECT_PASSWORD: return 'Incorrect password';
    }
  };

  useEffect(() => {
    let code: string | null = errorCode;
    if (!code) code = ERROR_CODES.UNKNOWN;
    const message: string = getErrorMessage(code);
    setErrorMessage(message);
  }, [errorCode]);

  return (
    <div className={wrapperStyle}>
      <p>{errorMessage}</p>
    </div>
  )
}

export default LoginError;
