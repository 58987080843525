import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./components/pages/App";
import configureStore from './configureStore';
import SubNavDispatcher from './js/sub-nav-dispatcher';

// styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-18-image-lightbox/style.css';
import './css/cupertino.css';
import './css/global.scss';

const subNavDispatcher = new SubNavDispatcher();
const store = configureStore();

const renderContent = () => {
  if (process.env.REACT_APP_STRICT_MODE === 'FALSE') {
    return (
      <React.Fragment>
        <App subNavDispatcher={subNavDispatcher} />
      </React.Fragment>
    );
  } else {
    return (
      <React.StrictMode>
        <App subNavDispatcher={subNavDispatcher} />
      </React.StrictMode>
    );
  }
};

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <Provider store={store}>
    {renderContent()}
  </Provider>
);