import classnames from 'classnames';
import { gsap } from "gsap";
import { cloneElement, FC, isValidElement, ReactElement, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setShowcaseCategory } from "../../../actions";
import style from '../../../css/common/nav/nav-element.module.scss';
import SubNavDispatcher from '../../../js/sub-nav-dispatcher';

interface IProps {
  loggedIn?: boolean;
  wide?: boolean;
  sub?: boolean;
  publicRoute?: boolean;
  label: string;
  pathname: string;
  hash?: string;
  setOpenNavNarrow?: Function;
  subNavDispatcher?: SubNavDispatcher;
  isLogout?: boolean;
  forceClose?: boolean;
  children?: ReactElement | Array<any>;
  onSetShowcaseCategory: Function;
}

/**
 * Element for the navigation or parent element, containing a child set of navigation elements.
 */
const NavElement: FC<IProps> = ({
  wide = true,
  label,
  pathname,
  hash,
  onSetShowcaseCategory,
  loggedIn = false,
  sub = false,
  publicRoute = false,
  setOpenNavNarrow,
  subNavDispatcher,
  isLogout = false,
  forceClose = false,
  children
}): ReactElement => {
  const navigate = useNavigate();
  const [listSelected, setListSelected] = useState(false);
  const [overSub, setOverSub] = useState(false);
  const disabledLinkStyle = classnames({
    [style.link]: true,
    [style.disabledLink]: true
  });

  // Handles the forced closure of a sub menu.
  useEffect(() => {
    if (forceClose && wide && children && subNavDispatcher && listSelected) {
      subNavDispatcher.emit(SubNavDispatcher.CLOSE_SUB_WIDE);
      setListSelected(false);
    }
  }, [forceClose, children, listSelected, subNavDispatcher, wide]);

  const cloneNav = (element: ReactElement, index: number = -1): ReactElement | null => {
    if (element.type === "p" || element.type === "div") {
      return element;
    }

    return isValidElement(element) ?
      cloneElement(element as ReactElement, {
        key: index,
        loggedIn: loggedIn,
        setOpenNavNarrow: setOpenNavNarrow,
        wide: wide,
        sub: true,
        subNavDispatcher: subNavDispatcher
      }) :
      null
  };

  /**
   * Renders a child set of navigation elements.
   */
  const renderChildren = (): ReactElement | null => {
    // As well as a list, it could be a single element.
    let _children: Array<any> | ReactElement | null = null;

    // The parent element is currently selected.
    if (children && listSelected) {
      if (children instanceof Array) { // There is more than one child.
        _children = (children as any).map((element: ReactElement, index: number) => {
          return cloneNav(element, index);
        })
      } else if (children instanceof Object) { // there is only one child
        _children = cloneNav(children);
      }

      return (
        <div className={style.navList}>
          {_children}
        </div>
      )
    } else {
      return null;
    }
  };

  // ANIMATE TEXT INWARDS
  //
  useEffect(() => {
    const el: HTMLElement | null = document.getElementById(`sub${pathname}`);
    let tween: any = null;

    if (el) {
      tween = gsap.to(
        el,
        {
          marginLeft: overSub ? '1.25rem' : '0.5rem',
          marginRight: overSub ? '-0.25rem' : '0.5rem',
          duration: 0.6,
          ease: "power4.out"
        }
      );
    }

    return () => {
      if (tween) tween.kill();
    };
  }, [overSub, pathname]);

  /**
   * Renders a link element. If openSub is true, the link will acts as a parent,
   * opening a child set of links.
   * @param openSub - Whether the link acts as a parent, opening a child set of links.
   */
  const renderLink = (openSub: boolean = false): ReactElement => {
    const linkStyle = classnames({
      [style.link]: true,
      [style.sub]: sub,
      [style.logout]: isLogout,
    });

    if (isLogout) {
      return (
        <a href=" "
          onClick={(e) => {
            e.preventDefault();

            // Hide the entire menu (if narrow screen).
            if (setOpenNavNarrow) setOpenNavNarrow(false);
            if (subNavDispatcher) subNavDispatcher.emit(SubNavDispatcher.LOGOUT);
          }}>
          <p className={linkStyle}>{label}</p>
        </a>
      )
    } else {
      if (openSub) {
        // The button toggles the list of sub links on and off.
        return (
          <a href=" "
            onClick={(e) => {
              e.preventDefault();

              if (wide && subNavDispatcher) {
                if (listSelected) {
                  subNavDispatcher.emit(SubNavDispatcher.CLOSE_SUB_WIDE);
                } else {
                  subNavDispatcher.emit(SubNavDispatcher.OPEN_SUB_WIDE);
                }
              }

              setListSelected(!listSelected);
            }}>
            <p className={linkStyle}>{label}</p>
          </a>
        )
      } else {
        return (
          <a href=" "
            onClick={(e) => {
              e.preventDefault();

              if (wide) {
                // Hide any other lists of sub links by mimicking a sub menu open event.
                if (subNavDispatcher) subNavDispatcher.emit(SubNavDispatcher.OPEN_SUB_WIDE, true);
                if (subNavDispatcher) subNavDispatcher.emit(SubNavDispatcher.CLOSE_SUB_WIDE);
              } else {
                // Hide the entire menu (if narrow screen).
                if (setOpenNavNarrow) {
                  // As the entire thread of the setOpenNavNarrow() commands does not complete
                  // before the next page is navigated to, we set the scroll behaviour here
                  // instead. This ensures that future links are scrolled to smoothly.
                  // UPDATE: This has been commented out due to it causing a jump to the top
                  // of the page, before scrolling to the selected location. This was occuring
                  // on narrow screen only.
                  // document.documentElement.style.scrollBehavior = "smooth";

                  setOpenNavNarrow(false);
                }
              }

              onSetShowcaseCategory(null);

              if (typeof hash === "undefined") {
                navigate(`${pathname}`);
              } else {
                navigate(`${pathname}${hash}`);
              }
            }}
            onMouseOver={() => {
              if (wide && sub) setOverSub(true);
            }}
            onMouseOut={() => {
              if (wide && sub) setOverSub(false);
            }}
            onMouseUp={() => {
              if (wide && sub) setOverSub(false);
            }}
          >
            {
              wide && sub ?
                <p id={`sub${pathname}`} className={linkStyle}>{label}</p> :
                <p className={linkStyle}>{label}</p>
            }
          </a>
        )
      }
    }
  };

  /**
   * Renders a single link or a parent link, which includes a child set of links.
   */
  const renderLinkOrList = (): ReactElement => {
    if (loggedIn || publicRoute) {
      if (children) {
        return (
          <>
            {/* Parent link to operate as an 'opener', and not link to another route. */}
            {renderLink(true)}
            {renderChildren()}
          </>
        );
      } else {
        return renderLink();
      }
    } else {
      return <p className={disabledLinkStyle}>{label}</p>
    }
  }

  useEffect(() => {
    const openSubWideHandler = () => setListSelected(false);

    const wideTintClickedHandler = () => {
      if (!sub) {
        setListSelected(false);
      }
    };

    if (subNavDispatcher) {
      subNavDispatcher.on(SubNavDispatcher.OPEN_SUB_WIDE, openSubWideHandler);
      subNavDispatcher.on(SubNavDispatcher.WIDE_TINT_CLICKED, wideTintClickedHandler);
    }

    return () => {
      if (subNavDispatcher) {
        subNavDispatcher.off(SubNavDispatcher.OPEN_SUB_WIDE, openSubWideHandler);
        subNavDispatcher.off(SubNavDispatcher.WIDE_TINT_CLICKED, wideTintClickedHandler);
      }
    };
  }, [sub, subNavDispatcher]);

  const wrapperStyle = classnames({
    [style.wrapper]: true,
    [style.narrowWrapper]: !wide
  });

  return (
    <div className={wrapperStyle}>
      {renderLinkOrList()}
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetShowcaseCategory: (category: string) => dispatch(setShowcaseCategory(category)),
  }
}

export default connect(null, mapDispatchToProps)(NavElement);