import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import style from '../../../css/common/login/login.module.scss';
import Logo from '../../../images/logo.png';
import { logIn } from "../../../js/firebase";
import { ERROR_CODES } from '../../../js/statics';
import Button from '../ui/Button';
import ScreenTint from '../ui/ScreenTint';
import LoginError from './LoginError';
import LoginSpinner from './LoginSpinner';
import TextInput from './TextInput';

interface IProps {
  loginRoute: string;
  onSuccess: Function;
  onCancel: Function;
}

const Login: FC<IProps> = ({ loginRoute, onSuccess, onCancel }): ReactElement => {
  const emailRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [checkingLogin, setCheckingLogin] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();

  const login = async () => {
    let eCode: string | null = null;

    if (emailRef.current && emailRef.current['value']) {
      if (pwRef.current && pwRef.current['value']) {
        try {
          setCheckingLogin(true);
          await logIn(emailRef.current['value'], pwRef.current['value']);
          setCheckingLogin(false);
          navigate(loginRoute);
          onSuccess();
        } catch (code: any) {
          eCode = code;
          setCheckingLogin(false);
        }
      } else {
        eCode = ERROR_CODES.NO_PASSWORD;
      }
    } else {
      eCode = ERROR_CODES.NO_EMAIL;
    }

    if (eCode) {
      setErrorCode(eCode);
      setShowError(true);
    }
  }

  useEffect(() => {
    let timeout: any;

    if (showError) {
      timeout = setTimeout(() => setShowError(false), 2000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    }
  }, [showError]);

  return (
    <div className={style.outerWrapper}>
      <div className={style.wide}><ScreenTint wide={true} onClick={onCancel} /></div>
      <div className={style.narrow}><ScreenTint wide={false} onClick={onCancel} /></div>
      <div className={style.wrapper}>
        <div className={style.titleWrapper}>
          <h2>Login</h2>
          <div className={style.logoWrapper}>
            <img src={Logo} alt='Logo' className={style.logo} />
          </div>
        </div>
        <TextInput disabled={checkingLogin} label="Email" ref={emailRef} className={style.emailInput} />
        <TextInput disabled={checkingLogin} label="Password" inputType='password' ref={pwRef} />
        <p>Please contact me or your recruitment agency to provide you with a password.</p>
        <p><b>Email</b>: <span className={style.emailLink}>malc@malcadams.co.uk</span></p>
        <div className={style.buttons}>
          <Button disabled={checkingLogin} label="Cancel" bgcolor="#919191" bgcolorHover='#bdbdbd' callback={onCancel} />
          <Button disabled={checkingLogin} className={style.okButton} label="OK" bgcolor="#20b2aa" bgcolorHover="#18ebe0" callback={login} />
        </div>
        <LoginSpinner show={checkingLogin} />
        <LoginError showError={showError} errorCode={errorCode} />
      </div>
    </div>
  )
};

export default Login;