import { FC } from "react";
import style from '../../../css/common/nav/nav-open-button.module.scss';
import CloseMenuIcon from '../../../images/close-menu-icon.png';

interface IProps {
  setOpenNavNarrow: Function;
  setShowTintNarrow: Function;
}

const NavCloseButton: FC<IProps> = ({ setOpenNavNarrow, setShowTintNarrow }) => {
  return <a className={style.button} href=" " onClick={(e) => {
    e.preventDefault();
    setOpenNavNarrow(false)
    setShowTintNarrow(false);
  }}>
    <img className={style.menuIcon} src={CloseMenuIcon} alt='close menu icon' />
  </a>
};

export default NavCloseButton;