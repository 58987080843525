import style from '../../../css/common/ui/logo-collection.module.scss';

const LogoCollection = () => {
  const list: string[] = [
    "gsk",
    "bbc",
    "sky",
    "booktrust",
    "eon",
    "cartoon-network",
    "kia",
    "premier-league",
    "nickelodeon",
    "world-book-day",
    "astrazeneca",
    "e-one",
    "penguin",
    "tesco",
    "acamar"
  ];

  const renderLogos = () => {
    return list.map((item, index) => {
      return (
        <div className={style.logo} key={index}>
          <img src={`/images/about/logos/${item}.png`} alt={item} />
        </div>
      )
    });
  };

  return (
    <div className={style.wrapper}>
      {renderLogos()}
    </div>
  )
};

export default LogoCollection;