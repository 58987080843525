import { FC, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import { connect } from 'react-redux';
import pageStyle from "../../css/pages/page.module.scss";
import style from '../../css/pages/showcase.module.scss';
import showcaseData from '../../data/showcase.json';
import { PROJECT_TYPES } from "../../js/statics";
import { getInnerWrapperStyle, getOuterWrapperStyle } from "../../js/style-utils";
import { isTouchDevice } from "../../js/utils";
import { getShowcaseCategory } from "../../selectors";
import ShowcaseSection from "../common/showcase/ShowcaseSection";
import TransitionIn from "../common/ui/TransitionIn";

interface IProps {
  first?: boolean;
  colorIndex: number;
  showcaseCategory: string;
};

const Showcase: FC<IProps> = ({ first = false, colorIndex, showcaseCategory }) => {
  const dataWebsites: any = showcaseData.filter((item: any) => item.name === "websites")[0];
  const dataGamesAndDesign: any = showcaseData.filter((item: any) => item.name === "gamesAndDesign")[0];
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imagePath, setImagePath] = useState<string | null>(null);
  const outerWrapperStyle = getOuterWrapperStyle(first, colorIndex);
  const innerWrapperStyle = getInnerWrapperStyle(first);

  const onShowcaseMenuOpenImage: any = (index: number, data: any) => {
    setImagePath(data.data[index].imageLarge);
    setLightboxOpen(true);
  };

  const onToTop = () => {
    const section = document.getElementById(`showcase-section-${0}`);

    if (section) {
      const bodyRect = document.body.getBoundingClientRect();
      const sectionRect = section.getBoundingClientRect();
      const offset = sectionRect.top - bodyRect.top;
      // 120px allows for the nav bar
      window.scrollTo({ top: offset - 120, behavior: 'smooth' });
    }
  };

  return (
    <>
      {lightboxOpen && imagePath && <Lightbox
        mainSrc={imagePath}
        onCloseRequest={() => setLightboxOpen(false)}
        enableZoom={isTouchDevice()}
      />}
      <a id="showcase" href="_"> </a>
      <div className={outerWrapperStyle}>
        <div className={pageStyle.wrapper}>
          <div className={innerWrapperStyle}>
            <TransitionIn>
              <h1>showcase.</h1>
            </TransitionIn>
            <div className={pageStyle.contentBlock}>
              <p>The two sections below present a collection of websites, HTML5 canvas games and design work, which I've had the pleasure to work on over recent years.</p>
              <p className={style.highlightText}>Each project provides an <span className={style.infoHighlight}>&nbsp;&nbsp;INFO&nbsp;&nbsp;</span> link, allowing discovery of how and why the project was created.</p>
              <br />
              <a id="showcase-inner" style={{ height: '20px' }} href="_"> </a>
              <TransitionIn
                scrollPosition={0.95}
                disabled={showcaseCategory === PROJECT_TYPES.WEBSITES || showcaseCategory === PROJECT_TYPES.GAMES_AND_DESIGN}
              >
                <ShowcaseSection
                  index={0}
                  startOpen={showcaseCategory === PROJECT_TYPES.WEBSITES}
                  title="Websites"
                  data={dataWebsites}
                  openImageCallback={(index: number) => {
                    onShowcaseMenuOpenImage(index, dataWebsites);
                  }}
                  toTopCallback={onToTop}
                  intro="A selection of websites, created for commercial use and for my own personal development."
                />
              </TransitionIn>
              <TransitionIn
                scrollPosition={0.95}
                disabled={showcaseCategory === PROJECT_TYPES.WEBSITES || showcaseCategory === PROJECT_TYPES.GAMES_AND_DESIGN}
                addDelay={0.2}
              >
                <ShowcaseSection
                  index={1}
                  startOpen={showcaseCategory === PROJECT_TYPES.GAMES_AND_DESIGN}
                  title="HTML5 Games &amp; Design Work"
                  data={dataGamesAndDesign}
                  openImageCallback={(index: number) => {
                    onShowcaseMenuOpenImage(index, dataGamesAndDesign);
                  }}
                  toTopCallback={onToTop}
                  intro="Three HTML5 canvas games and two examples of my artwork."
                />
              </TransitionIn>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = (state: any) => {
  return {
    showcaseCategory: getShowcaseCategory(state),
  }
}

export default connect(mapStateToProps)(Showcase);