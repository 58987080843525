interface IRoutes {
  [id: string]: string;
};

const ROUTES: IRoutes = {
  DESIGN_HOLISTICURE: "holisticure-design",
  DESIGN_STAR_PROPERTIES: "star-properties-design",
  GAME_CHRISTMAS_COUNTDOWN: "christmas-countdown-game",
  GAME_YEAR_ON_FARM: "year-on-farm-game",
  GAME_KARATE_CATS: "karate-cats-game",
  WEBSITE_CHARLIES: "charlies-website",
  WEBSITE_CLEARWELL: "clearwell-website",
  WEBSITE_DOCKYARD: "dockyard-website",
  WEBSITE_HOLISTICURE: "holisticure-website",
  WEBSITE_MASSAGE: "massage-website",
  WEBSITE_PORTFOLIO: "portfolio-website",
  WEBSITE_WEATHER: "weather-website",
  WEBSITE_AQUARIUMS: "aquariums-website",
  WEBSITE_FINTECH: "fintech-website",
  WEBSITE_PROFILES: "profiles-website"
};

interface IProjects {
  [id: string]: string;
};

const PROJECTS: IProjects = {
  DESIGN_HOLISTICURE: "design_holisticure",
  DESIGN_STAR_PROPERTIES: "design_star_properties",
  GAME_CHRISTMAS_COUNTDOWN: "game_christmas_countdown",
  GAME_YEAR_ON_FARM: "game_year_on_farm",
  GAME_KARATE_CATS: "game_karate_cats",
  WEBSITE_CHARLIES: "website_charlies",
  WEBSITE_CLEARWELL: "website_clearwell",
  WEBSITE_DOCKYARD: "website_dockyard",
  WEBSITE_HOLISTICURE: "website_holisticure",
  WEBSITE_MASSAGE: "website_massage",
  WEBSITE_PORTFOLIO: "website_portfolio",
  WEBSITE_WEATHER: "website_weather",
  WEBSITE_AQUARIUMS: "website_aquariums",
  WEBSITE_FINTECH: "website_fintech",
  WEBSITE_PROFILES: "website_profiles"
};

interface IProjectTypes {
  [id: string]: string;
};

const PROJECT_TYPES: IProjectTypes = {
  GAMES_AND_DESIGN: "gamesAndDesign",
  WEBSITES: "websites"
};

interface IRoles {
  [id: string]: string;
};

const ROLES: IRoles = {
  FREELANCER: "freelance",
  SOLE_DEVELOPER: "sole",
  LEAD_DEVELOPER: "lead",
  TEAM_MEMBER: "team"
}

interface IErrorCodes {
  [id: string]: string;
};

const ERROR_CODES: IErrorCodes = {
  NO_EMAIL: "noEmail",
  NO_PASSWORD: "noPassword",
  INCORRECT_EMAIL: "incorrectEmail",
  INCORRECT_PASSWORD: "incorrectPassword",
  TOO_MANY_TRIES: "tooManyTries",
  UNKNOWN: "unknown"
};

export { ROUTES, PROJECTS, PROJECT_TYPES, ROLES, ERROR_CODES };
