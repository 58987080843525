import { FC, useEffect } from "react";
import { Navigate } from "react-router";

interface IProps {
  route: string;
  loggedIn: boolean;
  loggingOut: boolean;
  children: any;
  onFailure: Function;
}

const RequireAuth: FC<IProps> = ({ route, loggedIn, loggingOut, children, onFailure }) => {
  useEffect(() => {
    // Checks the loggingOut state so any chnage in loggedIn state is ignored during logout
    if (!loggedIn && !loggingOut) onFailure(route);
  }, [loggedIn, loggingOut, onFailure, route]);

  return loggedIn ? children : <Navigate to="/" replace />;
}

export default RequireAuth;