import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GridItem from './GridItem';

interface IProps {
  openImageCallback: Function;
  data: any;
}

const GridMenu: FC<IProps> = ({ openImageCallback, data }) => {
  // Use temporarily to inform other items to hide.
  const [itemSelected, setItemSelected] = useState(false);
  const [itemHover, setItemHover] = useState(false);

  // For use with touch devices.
  const onItemSelected = () => {
    setItemSelected(true);
    // next tick
    setTimeout(() => setItemSelected(false), 1);
  };

  // For use with pointer devices.
  const onItemHover = () => {
    setItemHover(true);
    // next tick
    setTimeout(() => setItemHover(false), 2)
  };

  const renderRowItems = (indexes: Array<number>): Array<JSX.Element> => {
    return indexes.map((itemIndex, index) => {
      return (
        <Col xs={12} md={6} lg={3} key={index}>
          <GridItem
            index={itemIndex}
            projectType={data.name}
            data={data.data[itemIndex]}
            selectedCallback={onItemSelected}
            itemSelected={itemSelected}
            hoverCallback={onItemHover}
            itemHover={itemHover}
            openImageCallback={openImageCallback}
          />
        </Col>
      )
    })
  };

  const renderAllRows = (): Array<JSX.Element> => {
    const numRows: number = Math.ceil(data.data.length / 4);
    const rows: Array<JSX.Element> = [];
    let numCols: number;
    let indexList: Array<number>;

    // Rows.
    for (let i = 0; i < numRows; i++) {
      indexList = [];

      numCols = data.data.length - (i * 4);
      if (numCols > 4) numCols = 4;

      // Columns.
      for (let j = i * 4; j < i * 4 + numCols; j++) {
        indexList.push(j);
      }

      rows.push(<Row key={i}>{renderRowItems([...indexList])}</Row>);
    }

    return rows;
  };

  return (
    <Container style={{ marginTop: "30px" }}>
      {renderAllRows()}
    </Container>
  )
};

export default GridMenu;