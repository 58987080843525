import classNames from 'classnames';
import { FC } from 'react';
import aboutStyle from '../../../css/pages/about.module.scss';
import pageStyle from "../../../css/pages/page.module.scss";
import SkillsList from "../../common/skills-list/SkillsList";

interface IProps {
  selected: boolean;
};

const Skills: FC<IProps> = ({ selected }) => {
  const contentBlockStyle = classNames({
    [pageStyle.contentBlock]: true,
    [aboutStyle.contentBlock]: true
  });

  return (
    <div className={contentBlockStyle}>
      <h2>Skills</h2>
      <SkillsList disabled={!selected} />
    </div>
  )
};

export default Skills;