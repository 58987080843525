import classNames from 'classnames';
import { FC } from 'react';
import style from '../../../css/common/login/login-spinner.module.scss';
import spinner from '../../../images/spinner.gif';

interface IProps {
  show?: boolean;
};

const LoginSpinner: FC<IProps> = ({ show = false }) => {
  const wrapperStyle = classNames({
    [style.spinnerWrapper]: true,
    [style.show]: show
  });

  return (
    <div className={wrapperStyle}>
      <div className={style.spinnerBand}>
        <img className={style.spinner} src={spinner} alt="Spinner" />
      </div>
    </div>
  )
};

export default LoginSpinner;
