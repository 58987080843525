import classNames from 'classnames';
import { FC } from 'react';
import aboutStyle from '../../../css/pages/about.module.scss';
import pageStyle from "../../../css/pages/page.module.scss";
import PopInImage from '../../common/ui/PopInImage';
import { SIDE } from '../../common/ui/TransitionIn';

interface IProps {
  selected: boolean;
};

const Qualities: FC<IProps> = ({ selected = false }) => {
  const contentBlockStyle = classNames({
    [pageStyle.contentBlock]: true,
    [aboutStyle.contentBlock]: true
  });

  return (
    <>
      <div className={contentBlockStyle}>
        <PopInImage
          src="/images/about/team.png"
          alt="Team"
          side={SIDE.RIGHT}
          disabled={!selected}
        />
        <div>
          <h2>Qualities</h2>
          <p>I'm a strong team member, who interacts and performs productively alongside others, adopting new working techniques, whilst proposing ideas of my own. Being experienced in working within teams which adopt Agile methodologies, I'm very familiar with the Scrum framework and Sprints. Coming from a design background has allowed me to communicate effectively with design teams, achieving their goals and standards, as well as technical.</p>
          <p>Many applications, which I've developed, have been for young children, so I instinctively strive to ensure that applications are intuitive and engaging. Everything must have responsiveness built-in from day one, and I understand the importance of cross-device, cross-browser and cross-platform (not cross-fingers) testing.</p>
        </div>
        <div className={pageStyle.clear}></div>
      </div>
      <div className={contentBlockStyle}>
        <PopInImage
          src="/images/about/solution.gif"
          alt="Solution"
          side={SIDE.LEFT}
          disabled={!selected}
        />
        <div>
          <p>As in everyday life, I naturally tend to hunt for solutions to problems; documenting, organising, systemising and automating processes, with the goal of avoiding the repetition of tasks and errors.</p>
          <p>I have a keen eye for accuracy, consistency and overall quality; self-motivated, with a passion for improving my personal skill-set, while inspiring others in their own capabilities.</p>
        </div>
        <div className={pageStyle.clear}></div>
      </div>
    </>
  )
};

export default Qualities;