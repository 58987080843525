import classNames from "classnames";
import { FC } from "react";
import pageStyle from '../../../css/pages/page.module.scss';
import TransitionIn, { SIDE, TRANSITION } from "./TransitionIn";

interface IProps {
  src: string;
  alt: string;
  side: string;
  scrollPosition?: number;
  elementEdge?: string;
  disabled?: boolean,
  customStyle?: string;
};

/**
 * @param src Path to the image.
 * @param alt Alt text to apply to the image.
 * @param side Side of the screen which the image appears on wider devices: SIDE.LEFT or SIDE.RIGHT. Default: SIDE.LEFT.
 * @param scrollPosition Position down the screen, from 0 - 1, where 0 is the top. Default: 0.8.
 * @param elementEdge Edge of the element which triggers the animation: EDGE.TOP or EDGE.BOTTOM. Default: EDGE.TOP.
 * @param disabled Whether the transition should be stopped from operating.
 */
const PopInImage: FC<IProps> = ({
  src,
  alt,
  side,
  scrollPosition,
  elementEdge,
  disabled = false,
  customStyle = ""
}) => {
  const imageStyle = classNames({
    [pageStyle.leftImage]: side === SIDE.LEFT,
    [pageStyle.rightImage]: side === SIDE.RIGHT,
    [pageStyle.contentImageWrapper]: true,
    [customStyle]: true
  });

  return (
    <div className={imageStyle}>
      <TransitionIn
        transition={TRANSITION.POP}
        scrollPosition={scrollPosition}
        elementEdge={elementEdge}
        disabled={disabled}
      >
        <img className={pageStyle.contentImage} src={src} alt={alt} />
      </TransitionIn>
    </div>
  )
};

export default PopInImage;