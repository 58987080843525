import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import overlayStyle from '../../../css/common/email-form/overlay.module.scss';
import style from '../../../css/common/email-form/waiting-overlay.module.scss';

interface IProps {
  show?: boolean;
};

const WaitingOverlay: FC<IProps> = ({ show = false }) => {
  const [hasDisplayed, setHasDisplayed] = useState<boolean>(false); // Whether yet displayed.
  const [inDOM, setInDOM] = useState<boolean>(false); // Whether to add to the DOM.
  const [transStateIn, setTransStateIn] = useState<boolean>(false); // Whether currently transition in or out state.
  const [transIn, setTransIn] = useState<boolean>(false); // Whether currently transitioning in.
  const [transOut, setTransOut] = useState<boolean>(false); // Whether currently transitioning out.

  const wrapperRef = useRef<HTMLDivElement>(null);

  const wrapperStyle = classNames({
    [overlayStyle.wrapper]: true,
    [style.wrapper]: true,
    [overlayStyle.showWrapper]: inDOM,
    [overlayStyle.fadeInWrapper]: transStateIn,
    [overlayStyle.fadeOutWrapper]: hasDisplayed && !transStateIn
  });

  const boxStyle = classNames({
    [overlayStyle.box]: true,
    [style.box]: true
  });

  useEffect(() => {
    if (show) {
      // Stop the transition being requested more than once.
      if (!transIn) {
        // Swap from trans out to trans in.
        setTransStateIn(true);

        // Swap current transitions.
        setTransOut(false);
        setTransIn(true);

        // Add to the DOM.
        setInDOM(true);

        // It has now been displayed.
        setHasDisplayed(true);
      }
    } else {
      // Stop the transition on instantiation.
      // Stop the transition being requested more than once.
      if (hasDisplayed && !transOut) {
        // Swap from trans in to trans out.
        setTransStateIn(false);

        // Swap current transitions.
        setTransIn(false);
        setTransOut(true);

        // Remove from the DOM.
        setTimeout(() => setInDOM(false), 500);
      }
    }
  }, [show, hasDisplayed, transIn, transOut]);

  return (
    <div ref={wrapperRef} className={wrapperStyle}>
      <div className={boxStyle}>
        <div className={style.spinner}></div>
        <p>sending...</p>
      </div>
    </div>
  )
};

export default WaitingOverlay;
