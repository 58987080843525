import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FC, useEffect, useRef, useState } from 'react';
import style from '../../../css/common/skills-bar.module.scss';
import { getScreenHeight } from '../../../js/utils';

interface IProps {
  label: string;
  perc: number;
  barId: string;
  disabled?: boolean;
}

const SkillsBar: FC<IProps> = ({ label, perc, barId, disabled = false }) => {
  gsap.registerPlugin(ScrollTrigger);

  const showPercentage = false;

  // y position where the scrolling will begin.
  const [animStart, setAnimStart] = useState(0);
  const [animPlayed, setAnimPlayed] = useState(false);

  const parentRef = useRef(null);
  const maxTweenTime = 3;

  // Sets the screen height and changes on screen rotation.
  useEffect(() => {
    const resizeHandler = () => {
      // 20% into the page, from the bottom.
      setAnimStart(getScreenHeight() * 0.8);
    };

    window.addEventListener("resize", resizeHandler, false);
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  // Create the page scroll animation.
  useEffect(() => {
    let anim: any;

    if (!animPlayed && !disabled) {
      const parent: HTMLElement | null = parentRef.current;

      if (parent) {
        const element: HTMLElement | null = (parent as HTMLElement).querySelector(`#${barId}`);

        if (element) {
          anim = gsap.fromTo(
            element,
            {
              width: "0%"
            },
            {
              width: perc + "%",
              duration: (perc / 100) * maxTweenTime,
              ease: "Power3.out",
              onComplete: () => setAnimPlayed(true),
              scrollTrigger: {
                trigger: `#${barId}`,
                start: "top " + animStart
              }
            }
          );
        }
      }
    }

    return () => {
      if (anim) anim.kill();
    }
  }, [perc, barId, animStart, animPlayed, disabled]);

  return (
    <div ref={parentRef} className={style.wrapper}>
      <div className={style.label}>{label}</div>
      <div className={style.valueBarContainer}>
        <div id={barId} className={style.bar}></div>
        <div className={style.value}>{showPercentage ? `${perc}%` : ''}</div>
      </div>
    </div>
  )
}

export default SkillsBar;