import classNames from 'classnames';
import { FC } from 'react';
import style from '../../../css/common/showcase/grid-item-title.module.scss';

interface IProps {
  classname: string;
  data: any;

}

const GridItemTitle: FC<IProps> = ({ classname, data }) => {
  const wrapperStyle = classNames({ [style.wrapper]: true, [classname]: true });
  return (
    <div className={wrapperStyle}>
      <p>{data.title}</p>
    </div>
  )
}

export default GridItemTitle;