import EventEmitter from 'eventemitter3';

class SubNavDispatcher extends EventEmitter {
  static OPEN_MAIN_NARROW: string = 'openMainNarrow';
  static CLOSE_MAIN_NARROW: string = 'closeMainNarrow';
  static OPEN_SUB_WIDE: string = 'openSubWide';
  static CLOSE_SUB_WIDE: string = 'closeSubWide';
  static WIDE_TINT_CLICKED: string = 'wideTintClicked';
  static LOGOUT: string = 'logout';
};

export default SubNavDispatcher;