import classNames from 'classnames';
import { FC, ReactElement, useEffect, useRef } from 'react';
import style from '../../../css/common/ui/button.module.scss';
import { isTouchDevice } from '../../../js/utils';

interface IProps {
  label: string;
  bgcolor?: string;
  bgcolorHover?: string | undefined;
  callback?: any | null;
  className?: string;
  disabled?: boolean;
}

const Button: FC<IProps> = ({
  label,
  bgcolor = "grey",
  bgcolorHover,
  callback = null,
  className = '',
  disabled = false,
}): ReactElement => {
  if (typeof bgcolorHover === 'undefined') bgcolorHover = bgcolor;

  const wrapperStyle: string = classNames({
    [style.wrapper]: true,
    [className]: className
  });

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef) {
      const element: HTMLButtonElement = ((buttonRef as any).current);
      element.disabled = disabled;

      element.onclick = (e) => {
        e.preventDefault();
        callback && callback();
      };
    }
  }, [disabled, callback]);

  return (
    <a
      ref={buttonRef}
      className={wrapperStyle}
      style={{ backgroundColor: bgcolor }}
      href=" "
      onMouseOver={(e) => {
        e.preventDefault();

        if (!isTouchDevice()) {
          (buttonRef as any).current.style.backgroundColor = bgcolorHover;
        }
      }}
      onMouseOut={(e) => {
        e.preventDefault();

        if (!isTouchDevice()) {
          (buttonRef as any).current.style.backgroundColor = bgcolor;
        }
      }}
    >{label}</a>
  )
};

export default Button;