import classNames from 'classnames';
import { FC } from 'react';
import style from '../../../css/common/ui/screen-tint.module.scss';

interface IProps {
  wide: boolean;
  onClick?: Function;
};

const ScreenTint: FC<IProps> = ({ wide, onClick }) => {
  const tintStyle = classNames({
    [style.wrapper]: true,
    [style.wide]: wide,
    [style.narrow]: !wide
  });

  return <div className={tintStyle} onClick={() => {
    if (onClick) onClick();
  }}></div>
};

export default ScreenTint;