import classNames from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import style from '../../../css/common/ui/transition-in.module.scss';
import { getScreenHeight } from "../../../js/utils";

interface ITransition {
  [id: string]: string;
};

const TRANSITION: ITransition = {
  SLIDE: "slide",
  POP: "pop"
};

interface ISide {
  [id: string]: string;
};

const SIDE: ISide = {
  LEFT: "left",
  RIGHT: "right"
};

interface IElementEdge {
  [id: string]: string;
};

const EDGE: IElementEdge = {
  TOP: "top",
  BOTTOM: "bottom"
};

interface IProps {
  useDelay?: number;
  addDelay?: number;
  transition?: string;
  scrollPosition?: number;
  fromSide?: string;
  elementEdge?: string;
  customClassName?: string;
  onComplete?: Function;
  disabled?: Boolean;
  children: any;
};

/**
 * 
 * @param useDelay Whether to delay before playing the animation, rather than a scroll position.
 * @param addDelay Uses scrolling as a trigger but also adds an extra delay before playing.
 * @param transition Movement into the page: TRANSITION.SLIDE or TRANSITION.POP. Default: TRANSITION.SLIDE.
 * @param scrollPosition Position down the screen, from 0 - 1, where 0 is the top. Default: 0.8.
 * @param fromSide Side of the screen which the element slides on from: SIDE.LEFT or SIDE.RIGHT. Default: SIDE.LEFT.
 * @param elementEdge Edge of the element which triggers the animation: EDGE.TOP or EDGE.BOTTOM. Default: EDGE.TOP.
 * @param customClassName Additional custom style to apply.
 * @param onComplete Function to callback once the transition is complete.
 * @param disabled Whether the transition should be stopped from operating.
 */
const TransitionIn: FC<IProps> = ({
  useDelay = -1,
  addDelay = 0,
  transition = 'slide',
  scrollPosition = 0.8,
  fromSide = 'left',
  elementEdge = 'top',
  customClassName = '',
  onComplete = null,
  disabled = false,
  children
}) => {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);
  const [animStartY, setAnimStartY] = useState(0);
  const [animPlayed, setAnimPlayed] = useState(false);
  const [mounted, setMounted] = useState(false);

  const wrapperStyle = classNames({
    [style.wrapper]: !disabled,
    [style.left]: !disabled && transition === TRANSITION.SLIDE && fromSide === SIDE.LEFT,
    [style.right]: !disabled && transition === TRANSITION.SLIDE && fromSide === SIDE.RIGHT,
    [customClassName]: !disabled
  });

  const animate = useCallback((element: any, trans: string, completeCallback: Function | null) => {
    gsap.to(element, { opacity: 1, duration: 0.25, });

    switch (trans) {
      default:
      case TRANSITION.SLIDE:
        gsap.to(element, {
          left: 0, duration: 1, ease: "power4.out", onComplete: () => {
            if (mounted && completeCallback) completeCallback();
          }
        });
        break;
      case TRANSITION.POP:
        element.style.transform = "scale(0.5)";
        gsap.to(element, {
          scale: 1, duration: 0.8, ease: "elastic.out(0.5, 0.5)", onComplete: () => {
            if (mounted && completeCallback) completeCallback();
          }
        });
        break;
    }
  }, [mounted]);

  // HANDLE MOUNTED STATE
  //
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // RESIZE
  //
  // Sets the screen height and changes on screen rotation.
  useEffect(() => {
    // 20% into the page, from the bottom.
    const resizeHandler = () => setAnimStartY(getScreenHeight() * scrollPosition);
    window.addEventListener("resize", resizeHandler, false);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  }, [scrollPosition]);

  // Ensures that the height is calculated correctly for ScrollTrigger.
  useEffect(() => {
    setTimeout(() => ScrollTrigger.refresh(), 500);
  }, []);

  // TIME TRIGGER
  //
  // Handles the delayed version of the animation trigger, where a timer
  // is used, rather than the scroll position.
  useEffect(() => {
    let timer: any;

    if (!disabled) {
      if (ref.current && !animPlayed && useDelay !== -1) {
        timer = setTimeout(() => {
          setAnimPlayed(true);
          animate(ref.current, transition, onComplete);
        }, useDelay * 1000);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [animPlayed, useDelay, transition, animate, onComplete, disabled]);

  // SCROLL TRIGGER
  //
  // Handles the trigger of the animation based upon the scroll position.
  // If useDelay is set, this will not run.
  useEffect(() => {
    let anim: any;
    let timeout: any;

    if (!disabled) {
      if (ref.current && !animPlayed && useDelay === -1) {
        const element = ref.current;

        anim = gsap.to(
          element,
          {
            duration: 0.1,
            scrollTrigger: { trigger: element, start: `${elementEdge} ${animStartY}` },
            onStart: () => {
              timeout = setTimeout(() => {
                setAnimPlayed(true);
                animate(element, transition, onComplete);
              }, addDelay * 1000);
            }
          }
        );
      }
    }

    return () => {
      if (anim) anim.kill();
      if (timeout) clearTimeout(timeout);
    };
  }, [
    animPlayed,
    animStartY,
    useDelay,
    addDelay,
    transition,
    elementEdge,
    animate,
    onComplete,
    disabled
  ]);

  return (
    <div ref={ref} className={wrapperStyle}>{children}</div>
  )
};

export default TransitionIn;
export { TRANSITION, SIDE, EDGE };

