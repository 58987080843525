import classNames from 'classnames';
import { forwardRef, useEffect } from 'react';
import style from '../../../css/common/login/text-input.module.scss';

interface IProps {
  label: string;
  inputType?: string;
  className?: string;
  disabled?: boolean;
};

const TextInput = forwardRef<HTMLInputElement, IProps>((
  {
    label, inputType = 'text',
    className = '',
    disabled = false
  },
  ref) => {
  const inputStyle = classNames({
    [className]: true
  });

  useEffect(() => {
    if (ref) {
      const element: HTMLInputElement = ((ref as any).current);
      element.type = inputType;
      element.disabled = disabled;
    }
  }, [inputType, disabled, ref]);

  return (
    <div className={style.wrapper}>
      <p>{label}</p>
      <input ref={ref} type="text" spellCheck="false" className={inputStyle} />
    </div>
  )
});

export default TextInput;