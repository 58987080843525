import { User } from "firebase/auth";
import { FC, lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getUser, logOut } from "../../js/firebase";
import { PROJECTS, PROJECT_TYPES, ROUTES } from "../../js/statics";
import SubNavDispatcher from "../../js/sub-nav-dispatcher";
import { getProjectIds, getProjectPageConfig, getUniqueId } from "../../js/utils";
import Nav from "../common/nav/Nav";
import RequireAuth from "../common/RequireAuth";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import PageGroup from "./PageGroup";
import Showcase from "./Showcase";

interface IProps {
  subNavDispatcher: SubNavDispatcher;
}

const App: FC<IProps> = ({ subNavDispatcher }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [useRouter, setUseRouter] = useState<boolean>(false);
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const [loginRoute, setLoginRoute] = useState<string>('/');
  const [projectPageConfig, setProjectPageConfig] = useState<any>({});

  const WebsiteTemplate = lazy(() => import('./project-templates/WebsiteTemplate'));
  const GameTemplate = lazy(() => import('./project-templates/GameTemplate'));
  const DesignTemplate = lazy(() => import('./project-templates/DesignTemplate'));

  //-----------------------------------------------------
  // login
  //-----------------------------------------------------

  const onLogout = async (): Promise<void> => {
    try {
      await logOut();
      setLoggingOut(true); // protect the logged in state change
      setLoggedIn(false);
      setLoggingOut(false); // unprotect the logged in state change
    } catch (error) {
      console.error(error);
      return;
    }
  }

  const onLogin = (route = '/'): void => {
    setLoginRoute(route);
  };

  const onLoginSuccess = (): void => {
    setLoggedIn(true);
  };

  //-----------------------------------------------------
  // login - end
  //-----------------------------------------------------

  // AUTHENTICATION
  //
  useEffect(() => {
    const func = async () => {
      try {
        const user: User | null = await getUser();
        setLoggedIn(user !== null);

        // use the react router now the logged in state has been retrieved
        setUseRouter(true);
      } catch (error) {
        console.error(error);
      }
    };

    func();
  }, []);

  // BACKGROUND COLOUR
  //
  // Change grey background to white, otherwise, we get a grey page every
  // time we load a new page.
  useEffect(() => {
    if (loggedIn) document.body.style.backgroundColor = "#fff";
  }, [loggedIn]);

  // CONFIG DATA
  //
  // Build config data object for each project.
  useEffect(() => {
    const config: any = {};
    const websiteIds = getProjectIds(PROJECT_TYPES.WEBSITES);
    const gamesAndDesignIds = getProjectIds(PROJECT_TYPES.GAMES_AND_DESIGN);
    let i: number;

    for (i = 0; i < websiteIds.length; i++) {
      const c = getProjectPageConfig(PROJECT_TYPES.WEBSITES, websiteIds[i]);
      if (c) config[websiteIds[i]] = c;
    }

    for (i = 0; i < gamesAndDesignIds.length; i++) {
      const c = getProjectPageConfig(PROJECT_TYPES.GAMES_AND_DESIGN, gamesAndDesignIds[i]);
      if (c) config[gamesAndDesignIds[i]] = c;
    }

    setProjectPageConfig(config);
  }, []);

  const renderWebsiteProjectRoute = (
    path: string,
    projectId: string,
    customContents: { [id: string]: JSX.Element }
  ): JSX.Element => {
    return (
      <Route path={path} element={
        <RequireAuth
          route={path}
          loggedIn={loggedIn}
          loggingOut={loggingOut}
          onFailure={(route: string) => onLogin(route)}
        >
          <PageGroup subNavDispatcher={subNavDispatcher}>
            <Suspense fallback={null}>
              <WebsiteTemplate
                id={projectId}
                config={projectPageConfig[projectId]}
                projectType={PROJECT_TYPES.WEBSITES}
                key={getUniqueId('')}
              >
                {customContents[projectId]}
              </WebsiteTemplate>
            </Suspense>
          </PageGroup>
        </RequireAuth>
      } />
    );
  };

  const renderGameProjectRoute = (
    path: string,
    projectId: string,
    customContents: { [id: string]: JSX.Element }
  ): JSX.Element => {
    return (
      <Route path={path} element={
        <RequireAuth
          route={path}
          loggedIn={loggedIn}
          loggingOut={loggingOut}
          onFailure={(route: string) => onLogin(route)}
        >
          <PageGroup subNavDispatcher={subNavDispatcher}>
            <Suspense fallback={null}>
              <GameTemplate
                id={projectId}
                config={projectPageConfig[projectId]}
                projectType={PROJECT_TYPES.GAMES_AND_DESIGN}
                key={getUniqueId('')}
              >
                {customContents[projectId]}
              </GameTemplate>
            </Suspense>
          </PageGroup>
        </RequireAuth>
      } />
    );
  };

  const renderDesignProjectRoute = (
    path: string,
    projectId: string,
    customContents: { [id: string]: JSX.Element }
  ): JSX.Element => {
    return (
      <Route path={path} element={
        <RequireAuth
          route={path}
          loggedIn={loggedIn}
          loggingOut={loggingOut}
          onFailure={(route: string) => onLogin(route)}
        >
          <PageGroup subNavDispatcher={subNavDispatcher}>
            <Suspense fallback={null}>
              <DesignTemplate
                id={projectId}
                config={projectPageConfig[projectId]}
                projectType={PROJECT_TYPES.GAMES_AND_DESIGN}
                key={getUniqueId('')}
              >
                {customContents[projectId]}
              </DesignTemplate>
            </Suspense>
          </PageGroup>
        </RequireAuth>
      } />
    );
  };

  const getProjectCustomContents = (): { [id: string]: JSX.Element } => {
    const HolisticureWebsite = lazy(() => import('./project-custom/HolisticureWebsite'));
    const ClearwellWebsite = lazy(() => import('./project-custom/ClearwellWebsite'));
    const DockyardWebsite = lazy(() => import('./project-custom/DockyardWebsite'));
    const CharliesWebsite = lazy(() => import('./project-custom/CharliesWebsite'));
    const WeatherWebsite = lazy(() => import('./project-custom/WeatherWebsite'));
    const MassageWebsite = lazy(() => import('./project-custom/MassageWebsite'));
    const AquariumsWebsite = lazy(() => import('./project-custom/AquariumsWebsite'));
    const FintechWebsite = lazy(() => import('./project-custom/FintechWebsite'));
    const ProfilesWebsite = lazy(() => import('./project-custom/ProfilesWebsite'));
    const YearOnTheFarmGame = lazy(() => import('./project-custom/YearOnTheFarmGame'));
    const KarateCatsGame = lazy(() => import('./project-custom/KarateCatsGame'));
    const ChristmasCountdownGame = lazy(() => import('./project-custom/ChristmasCountdownGame'));
    const HolisticureDesign = lazy(() => import('./project-custom/HolisticureDesign'));
    const StarPropertiesDesign = lazy(() => import('./project-custom/StarPropertiesDesign'));

    return {
      [PROJECTS.WEBSITE_HOLISTICURE]: <Suspense fallback={null}><HolisticureWebsite /></Suspense>,
      [PROJECTS.WEBSITE_CLEARWELL]: <Suspense fallback={null}><ClearwellWebsite /></Suspense>,
      [PROJECTS.WEBSITE_DOCKYARD]: <Suspense fallback={null}><DockyardWebsite /></Suspense>,
      [PROJECTS.WEBSITE_CHARLIES]: <Suspense fallback={null}><CharliesWebsite /></Suspense>,
      [PROJECTS.WEBSITE_WEATHER]: <Suspense fallback={null}><WeatherWebsite /></Suspense>,
      [PROJECTS.WEBSITE_MASSAGE]: <Suspense fallback={null}><MassageWebsite /></Suspense>,
      [PROJECTS.WEBSITE_AQUARIUMS]: <Suspense fallback={null}><AquariumsWebsite /></Suspense>,
      [PROJECTS.WEBSITE_FINTECH]: <Suspense fallback={null}><FintechWebsite /></Suspense>,
      [PROJECTS.WEBSITE_PROFILES]: <Suspense fallback={null}><ProfilesWebsite /></Suspense>,
      [PROJECTS.GAME_YEAR_ON_FARM]: <Suspense fallback={null}><YearOnTheFarmGame /></Suspense>,
      [PROJECTS.GAME_KARATE_CATS]: <Suspense fallback={null}><KarateCatsGame /></Suspense>,
      [PROJECTS.GAME_CHRISTMAS_COUNTDOWN]: <Suspense fallback={null}><ChristmasCountdownGame /></Suspense>,
      [PROJECTS.DESIGN_HOLISTICURE]: <Suspense fallback={null}><HolisticureDesign /></Suspense>,
      [PROJECTS.DESIGN_STAR_PROPERTIES]: <Suspense fallback={null}><StarPropertiesDesign /></Suspense>
    };
  };

  const renderRouter = () => {
    const customContents = getProjectCustomContents();

    return (
      <BrowserRouter>
        <Nav loggedIn={loggedIn} onLogout={onLogout} subNavDispatcher={subNavDispatcher} />

        <Routes>
          <Route path="/" element={
            <PageGroup subNavDispatcher={subNavDispatcher}>
              <Home first={true} loggedIn={loggedIn} loginRoute={loginRoute} onLoginSuccess={onLoginSuccess} />
              {loggedIn && <Showcase colorIndex={2} />}
              {loggedIn && <About colorIndex={1} />}
              {loggedIn && <Contact colorIndex={2} />}
            </PageGroup>
          } />

          {renderWebsiteProjectRoute(ROUTES.WEBSITE_CHARLIES, PROJECTS.WEBSITE_CHARLIES, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_CLEARWELL, PROJECTS.WEBSITE_CLEARWELL, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_DOCKYARD, PROJECTS.WEBSITE_DOCKYARD, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_HOLISTICURE, PROJECTS.WEBSITE_HOLISTICURE, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_MASSAGE, PROJECTS.WEBSITE_MASSAGE, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_PORTFOLIO, PROJECTS.WEBSITE_PORTFOLIO, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_WEATHER, PROJECTS.WEBSITE_WEATHER, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_AQUARIUMS, PROJECTS.WEBSITE_AQUARIUMS, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_FINTECH, PROJECTS.WEBSITE_FINTECH, customContents)}
          {renderWebsiteProjectRoute(ROUTES.WEBSITE_PROFILES, PROJECTS.WEBSITE_PROFILES, customContents)}
          {renderGameProjectRoute(ROUTES.GAME_YEAR_ON_FARM, PROJECTS.GAME_YEAR_ON_FARM, customContents)}
          {renderGameProjectRoute(ROUTES.GAME_KARATE_CATS, PROJECTS.GAME_KARATE_CATS, customContents)}
          {renderGameProjectRoute(ROUTES.GAME_CHRISTMAS_COUNTDOWN, PROJECTS.GAME_CHRISTMAS_COUNTDOWN, customContents)}
          {renderDesignProjectRoute(ROUTES.DESIGN_HOLISTICURE, PROJECTS.DESIGN_HOLISTICURE, customContents)}
          {renderDesignProjectRoute(ROUTES.DESIGN_STAR_PROPERTIES, PROJECTS.DESIGN_STAR_PROPERTIES, customContents)}

        </Routes>
      </BrowserRouter>
    );
  };

  return (
    <div>
      {useRouter && renderRouter()}
    </div>
  )
};

export default App;