import classNames from "classnames";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { FC, useEffect, useRef, useState } from "react";
import style from '../../../css/common/ui/expand-section.module.scss';
import InsetContent from "../ui/InsetContent";
import GridMenu from "./GridMenu";

interface IProps {
  openImageCallback: Function;
  data: any;
  title: string;
  startOpen: boolean;
  index: number;
  toTopCallback: Function;
  intro?: string;
}

const ShowcaseSection: FC<IProps> = ({
  title,
  openImageCallback,
  data,
  startOpen,
  index,
  toTopCallback,
  intro = ""
}) => {
  const titleRef: any = useRef(null);
  const wrapperRef: any = useRef(null);
  const [selected, setSelected] = useState(startOpen);
  const [overTitle, setOverTitle] = useState(false);

  const contentWrapperStyle = classNames({
    [style.contentWrapper]: true,
    [style.open]: selected,

    // Don't animate to begin.
    [style.openAnim]: selected,
    [style.closeAnim]: !selected
  });

  const expandIconStyle = classNames({
    [style.expandIcon]: true,
    [style.expandIconOpen]: !selected,
    [style.expandIconClosed]: selected
  });

  useEffect(() => {
    const theTimeout = setTimeout(() => {
      ScrollTrigger.refresh()
    }, 500);

    return () => clearTimeout(theTimeout)
  }, [selected])

  useEffect(() => {
    if (titleRef) {
      gsap.to(
        titleRef.current,
        {
          marginLeft: overTitle ? '0.5rem' : '0',
          duration: 0.6,
          ease: "power4.out"
        }
      );
    }
  }, [overTitle]);

  return (
    <div id={`showcase-section-${index}`}>
      <div
        className={style.title}
        onClick={() => {
          const prevSelected = selected;
          setTimeout(() => setSelected(!prevSelected), 1);
        }}
        onMouseOver={() => setOverTitle(true)}
        onMouseOut={() => setOverTitle(false)}
        onMouseUp={() => setOverTitle(false)}
      >
        <div className={expandIconStyle}></div>
        <h2 ref={titleRef}>{title}</h2>
      </div>
      <div ref={wrapperRef} className={contentWrapperStyle}>
        {intro !== "" && <InsetContent><p>{intro}</p></InsetContent>}
        <GridMenu data={data} openImageCallback={(index: number) => {
          openImageCallback(index, data);
        }} />
        <a
          className={style.topButton} href=" "
          onClick={(e) => {
            e.preventDefault();
            toTopCallback();
          }}>
          <p>Back to top</p>
          <div className={style.arrow}></div>
        </a>
      </div>
    </div>
  )
}

export default ShowcaseSection;