import classNames from 'classnames';
import { forwardRef, useEffect } from 'react';
import style from '../../../css/common/email-form/email-text-area.module.scss';
import textStyle from '../../../css/common/email-form/email-text.module.scss';

interface IProps {
  label: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
};

const EmailTextArea = forwardRef<HTMLTextAreaElement, IProps>((
  {
    label,
    name,
    disabled = false,
    required = true
  },
  ref
) => {
  const wrapperStyle = classNames({
    [textStyle.wrapper]: true,
    [style.wrapper]: true
  });

  useEffect(() => {
    if (ref) {
      const element: HTMLTextAreaElement = ((ref as any).current);
      if (typeof name !== 'undefined') element.name = name;
      element.disabled = disabled;
      element.required = required;
    }
  }, [name, disabled, required, ref]);

  return (
    <div className={wrapperStyle}>
      <p>{label}</p>
      <textarea ref={ref} />
    </div>
  )
});

export default EmailTextArea;