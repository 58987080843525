import { useCallback, useEffect, useRef, useState } from 'react';
import style from '../../../css/common/email-form/email-form.module.scss';
import postEmail from '../../../js/post-email';
import { isStringEmpty } from '../../../js/utils';
import Button from '../ui/Button';
import TransitionIn, { EDGE, SIDE } from '../ui/TransitionIn';
import EmailTextArea from './EmailTextArea';
import EmailTextInput from './EmailTextInput';
import ResultOverlay from './ResultOverlay';
import WaitingOverlay from './WaitingOverlay';

const EmailForm = () => {
  const [resultMessage, setResultMessage] = useState<string>('');
  const [resultMessageColor, setResultMessageColor] = useState<string>('3e3e3e');
  const [showResultOverlay, setShowResultOverlay] = useState<boolean>(false);
  const [showWaitingOverlay, setShowWaitingOverlay] = useState<boolean>(false);
  const [postingForm, setPostingForm] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const clearForm = () => {
    (nameRef as any).current.value = '';
    (emailRef as any).current.value = '';
    (companyRef as any).current.value = '';
    (messageRef as any).current.value = '';
  };

  const validateForm = (
    name: string,
    email: string,
    message: string
  ): boolean => {
    let validationMessage: string | undefined;

    if (isStringEmpty(name)) validationMessage = 'Please enter your name.';
    else if (isStringEmpty(email)) validationMessage = 'Please enter your email address.';
    else if (isStringEmpty(message)) validationMessage = 'Please enter a message.';

    if (validationMessage) {
      setResultMessage(validationMessage);
      setResultMessageColor('f00');
      return false;
    }

    return true;
  };

  const showMessage = useCallback(() => {
    setTimeout(() => {
      if (mounted) {
        setShowResultOverlay(true);

        setTimeout(() => {
          if (mounted) {
            setShowResultOverlay(false);
            setPostingForm(false);
          }
        }, 2000);
      }
    }, 500);
  }, [mounted]);

  const postForm = useCallback(async () => {
    const name: string = (nameRef as any).current.value;
    const email: string = (emailRef as any).current.value;
    const company: string = (companyRef as any).current.value;
    const message: string = (messageRef as any).current.value;

    if (validateForm(name, email, message)) {
      setShowWaitingOverlay(true);

      try {
        await postEmail({ name: name, email: email, company: company, message: message });

        if (mounted) {
          setResultMessage('Thank you, your email was sent successfully.');
          setResultMessageColor('3e3e3e');
          clearForm();
        }
      } catch (error) {
        if (mounted) {
          setResultMessage('Sorry, your email failed to send. Please try again.');
          setResultMessageColor('f00');
        }
      }

      if (mounted) {
        setShowWaitingOverlay(false);
        showMessage();
      }
    } else {
      showMessage();
    }
  }, [showMessage, mounted]);

  // POST FORM TRIGGER
  //
  // Allows state change to initiate the posting of the form.
  useEffect(() => {
    if (postingForm) postForm();
  }, [postingForm, postForm]);

  // HANDLE MOUNTED STATE
  //
  // Sets the mounted state, which non-useEffect functions can check.
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return (
    <>
      <h2 className={style.title}>Email</h2>
      <div className={style.outerWrapper}>
        <div className={style.wrapper}>
          <div className={style.detailsBlock}>
            <TransitionIn customClassName={style.textInputSlideOn} scrollPosition={1} elementEdge={EDGE.BOTTOM}>
              <EmailTextInput ref={nameRef} label='Name' disabled={postingForm} />
            </TransitionIn>
            <TransitionIn customClassName={style.textInputSlideOn} scrollPosition={1} elementEdge={EDGE.BOTTOM} addDelay={0.2}>
              <EmailTextInput ref={emailRef} label='Email' inputType='email' disabled={postingForm} />
            </TransitionIn>
            <TransitionIn customClassName={style.textInputSlideOn} scrollPosition={1} elementEdge={EDGE.BOTTOM} addDelay={0.4}>
              <EmailTextInput ref={companyRef} label='Company (optional)' required={false} disabled={postingForm} />
            </TransitionIn>
          </div>
          <div className={style.messageBlock}>
            <TransitionIn customClassName={style.textAreaSlideOn} scrollPosition={0.9} fromSide={SIDE.RIGHT}>
              <EmailTextArea ref={messageRef} label='Message' name='message' disabled={postingForm} />
            </TransitionIn>
          </div>
        </div>
        <Button
          label='Send email'
          className={style.submitButton}
          bgcolor={'#20b2aa'}
          bgcolorHover={'#18ebe0'}
          callback={() => setPostingForm(true)}
          disabled={postingForm}
        />
      </div>
      <WaitingOverlay show={showWaitingOverlay} />
      <ResultOverlay
        message={resultMessage}
        messageColor={resultMessageColor}
        show={showResultOverlay}
      />
    </>
  )
};

export default EmailForm;
