import classNames from 'classnames';
import aboutStyle from '../../../css/pages/about.module.scss';
import style from '../../../css/pages/about/testimonials.module.scss';
import pageStyle from "../../../css/pages/page.module.scss";

const Testimonials = () => {
  const contentBlockStyle = classNames({
    [pageStyle.contentBlock]: true,
    [aboutStyle.contentBlock]: true
  });

  return (
    <div className={contentBlockStyle}>
      <h2>Testimonials</h2>
      <div className={style.paper}>
        <p className={style.title}>Technical Project Manager, BBC</p>
        <div className={style.quotes}></div>
        <div className={style.content}>
          <p className={style.message}>"I have worked with Malc on several successful CBeebies games, including the last two Christmas offerings, and it has always been a pleasure. His expertise as a developer is considerable, and he helped come up with ingenious solutions for the trickier projects. He never missed a build deadline and I 100% would love to work with him again."</p>
          <p className={style.name}>Alison Richer</p>
        </div>
      </div>
      <br />
      <div className={style.paper} style={{ transform: 'rotate(-2deg)' }}>
        <p className={style.title}>Senior Project Manager, Complete Control</p>
        <div className={style.quotes}></div>
        <div className={style.content}>
          <p className={style.message}>"Malc is a dedicated and professional developer. He would always produce well thought out solutions to complex requirements. Given the opportunity, I would love to work with him again."</p>
          <p className={style.name}>Robert Markham</p>
        </div>
      </div>
      <br />
      <div className={style.paper}>
        <p className={style.title}>Director, Charlie's Cancer Care</p>
        <div className={style.quotes}></div>
        <div className={style.content}>
          <p className={style.message}>"Malc was able to come into our organisation and determine very quickly what would best meet our needs, providing well thought out solutions in a professional and friendly manner. We look forward to working with Malc again."</p>
          <p className={style.name}>Joanne Sutherland</p>
        </div>
      </div>
    </div>
  )
};

export default Testimonials;