import classNames from "classnames";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import Sticky from 'react-stickynode';
import { setShowcaseCategory } from "../../../actions";
import style from '../../../css/common/nav/nav.module.scss';
import Logo from '../../../images/logo.png';
import { ROUTES } from "../../../js/statics";
import SubNavDispatcher from "../../../js/sub-nav-dispatcher";
import Button from "../ui/Button";
import NavCloseButton from "./NavCloseButton";
import NavElement from './NavElement';
import NavOpenButton from "./NavOpenButton";
import NavScreenTint from "./NavScreenTint";

interface IProps {
  loggedIn: boolean;
  onLogout: Function;
  subNavDispatcher: SubNavDispatcher;
  onSetShowcaseCategory: Function;
}

const Nav: FC<IProps> = ({ loggedIn, onLogout, subNavDispatcher, onSetShowcaseCategory }): ReactElement => {
  const navigate: NavigateFunction = useNavigate();

  const [openNavNarrow, setOpenNavNarrow] = useState(false);
  const [showTintWide, setShowTintWide] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const stickyWrapperStyle = classNames({
    [style.stickyWrapper]: true,
    [style.stickyWrapperShow]: showNav
  });

  const logout = useCallback(() => {
    onSetShowcaseCategory(null);
    navigate('/');
    onLogout();
  }, [onLogout, navigate, onSetShowcaseCategory]);

  // CONTROLS DISPLAY STATE OF THE NAV
  //
  // Uses the loggedIn state to apply a delayed showNav state,
  // allowing it to animate out before removing from the DOM.
  useEffect(() => {
    let timeout: any;

    if (loggedIn) {
      setShowNav(true);
    } else {
      timeout = setTimeout(() => setShowNav(false), 300);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [loggedIn]);

  // HANDLES SCREEN SIZING AND ROTATION
  //
  useEffect(() => {
    const resizeHandler = () => {
      // Closes the narrow nav.
      setOpenNavNarrow(false);
      setShowTintNarrow(false);

      // Force closes the narrow nav.
      setForceClose(true);
      setTimeout(() => setForceClose(false), 0);
    };

    window.addEventListener("resize", resizeHandler, false);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const [showTintNarrow, setShowTintNarrow] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  // Used for wide navigation only.
  const [forceClose, setForceClose] = useState(false);

  interface NavProps {
    label: string;
    pathname: string;
    hash?: string;
    wide: boolean;
    publicRoute?: boolean;
  };

  /**
   * Renders a top-level nav link - not a sub menu item.
   * @param label (string) - Label to display on the navigation element.
   * @param pathname (string) - Route to the page.
   * @param hash (string) - Optional. Hash anchor.
   * @param wide (boolean) - Whether the nav element is for wide screen; otherwise, narrow.
   * @param publicRoute (boolean) - Whether the route is publically available. If not, user will need to login.
   */
  const renderMajorNav: FC<NavProps> = ({ label, pathname, hash, wide, publicRoute = false }): ReactElement => {
    return (
      <NavElement
        label={label}
        pathname={pathname}
        hash={hash}
        publicRoute={publicRoute}
        loggedIn={loggedIn}
        setOpenNavNarrow={setOpenNavNarrow}
        wide={wide}
        subNavDispatcher={subNavDispatcher}
      />
    );
  }

  const renderNavMain = (wide: boolean): ReactElement => {

    const mainNavStyle = classNames({
      [style.mainNav]: true,
      [style.narrowNavOpen]: openNavNarrow
    });

    return (
      <div className={mainNavStyle}>
        {renderMajorNav({ label: 'Home', pathname: '/', wide: wide, publicRoute: true })}
        {renderMajorNav({ label: 'Showcase Menu', pathname: '/', hash: '#showcase', wide: wide })}
        {renderMajorNav({ label: 'About', pathname: '/', hash: '#about', wide: wide })}
        {renderMajorNav({ label: 'Contact', pathname: '/', hash: '#contact', wide: wide })}

        {/* The Showcase is declared in full, as renderMajorNav() can't accept children. */}
        <NavElement
          label='Full Project List'
          pathname='/'
          loggedIn={loggedIn}
          setOpenNavNarrow={setOpenNavNarrow}
          wide={wide}
          subNavDispatcher={subNavDispatcher}
          forceClose={forceClose}
        >
          <p className={style.subCategory}>Websites</p>
          <NavElement label='Holisticure' pathname={`/${ROUTES.WEBSITE_HOLISTICURE}`} />
          <NavElement label='Clearwell Caves' pathname={`/${ROUTES.WEBSITE_CLEARWELL}`} />
          <NavElement label='Charlies' pathname={`/${ROUTES.WEBSITE_CHARLIES}`} />
          <NavElement label='Fintech' pathname={`/${ROUTES.WEBSITE_FINTECH}`} />
          <NavElement label='Profiles' pathname={`/${ROUTES.WEBSITE_PROFILES}`} />
          <NavElement label='Massage Gloucester' pathname={`/${ROUTES.WEBSITE_MASSAGE}`} />
          <NavElement label='Dockyard' pathname={`/${ROUTES.WEBSITE_DOCKYARD}`} />
          <NavElement label='Wall Mounted Aquariums' pathname={`/${ROUTES.WEBSITE_AQUARIUMS}`} />
          <NavElement label='BBC Weather' pathname={`/${ROUTES.WEBSITE_WEATHER}`} />
          {/* <NavElement label='Portfolio' pathname={`/${ROUTES.WEBSITE_PORTFOLIO}`} /> */}
          <p className={style.subCategory}>HTML5 Games</p>
          <NavElement label='A Year On Your Farm' pathname={`/${ROUTES.GAME_YEAR_ON_FARM}`} />
          <NavElement label='Karate Cats' pathname={`/${ROUTES.GAME_KARATE_CATS}`} />
          <NavElement label='Christmas Countdown' pathname={`/${ROUTES.GAME_CHRISTMAS_COUNTDOWN}`} />
          <p className={style.subCategory}>Design Work</p>
          <NavElement label='Holisticure' pathname={`/${ROUTES.DESIGN_HOLISTICURE}`} />
          <NavElement label='Star Properties' pathname={`/${ROUTES.DESIGN_STAR_PROPERTIES}`} />
          <div className={style.bottomSpacer}></div>
        </NavElement>
        {
          !wide &&
          <NavElement
            label='Logout'
            pathname=''
            loggedIn={loggedIn}
            setOpenNavNarrow={setOpenNavNarrow}
            subNavDispatcher={subNavDispatcher}
            isLogout={true}
          />

        }
      </div>
    )
  };

  const renderNavWide = (): ReactElement => {
    const wideStyle = classNames({
      [style.wrapper]: true,
      [style.wide]: true,
      [style.wrapperLoggedIn]: loggedIn,
      [style.wrapperLoggedOut]: hasLoggedIn && !loggedIn
    });

    const logoutButtonStyle = classNames({
      [style.logoutButton]: true,
      [style.logoutButtonLoggedIn]: loggedIn
    });

    return (
      <>
        {
          showTintWide &&
          <div className={style.wide}>
            <NavScreenTint wide={true} setShowTintWide={setShowTintWide} subNavDispatcher={subNavDispatcher} />
          </div>
        }
        <div className={wideStyle}>
          <div className={style.innerWrapper}>
            {renderNavMain(true)}
            <Link to={{ pathname: '/' }} className={style.logoWrapper}>
              <img className={style.logo} src={Logo} alt='logo' />
            </Link>
          </div>
          <div className={style.logoutButtonOuterWrapper}>
            <div className={style.logoutButtonWrapper}>
              {
                loggedIn &&
                <Button
                  label="Logout"
                  className={logoutButtonStyle}
                  bgcolor="#ab8182"
                  bgcolorHover="indianred"
                  callback={logout}
                />
              }
            </div>
          </div>
        </div>
      </>
    )
  };

  const renderNavNarrow = (): ReactElement => {
    const narrowStyle = classNames({
      [style.wrapper]: true,
      [style.narrow]: true,
      [style.open]: openNavNarrow,
      [style.closed]: !openNavNarrow,
      [style.wrapperLoggedIn]: loggedIn,
      [style.wrapperLoggedOut]: hasLoggedIn && !loggedIn
    });

    return (
      <>
        {
          showTintNarrow && openNavNarrow &&
          <div className={style.narrow}>
            <NavScreenTint wide={false} setShowTintNarrow={setShowTintNarrow} setOpenNavNarrow={setOpenNavNarrow} />
          </div>
        }
        <div className={narrowStyle}>
          <div className={style.narrowUI}>
            <Link to={{ pathname: '/' }} className={style.logoWrapper}>
              <img className={style.logo} src={Logo} alt='logo' />
            </Link>
            {
              !openNavNarrow &&
              <NavOpenButton setOpenNavNarrow={setOpenNavNarrow} setShowTintNarrow={setShowTintNarrow} />
            }
            {
              openNavNarrow &&
              <NavCloseButton setOpenNavNarrow={setOpenNavNarrow} setShowTintNarrow={setShowTintNarrow} />
            }
          </div>
          {openNavNarrow && renderNavMain(false)}
        </div>
      </>
    )
  };

  // STORES WHETHER THE USER HAS ALREADY LOGGED IN, AT LEAST ONCE, DURING THE CURRENT SESSION
  //
  useEffect(() => {
    if (loggedIn) setHasLoggedIn(true);
  }, [loggedIn]);

  useEffect(() => {
    if (subNavDispatcher) {
      subNavDispatcher.emit(openNavNarrow ? SubNavDispatcher.OPEN_MAIN_NARROW : SubNavDispatcher.CLOSE_MAIN_NARROW);
    }
  }, [openNavNarrow, subNavDispatcher]);

  useEffect(() => {
    const openSubWideHandler = () => setShowTintWide(true);
    const closeSubWideHandler = () => setShowTintWide(false);
    const logoutHandler = () => logout();

    if (subNavDispatcher) {
      subNavDispatcher.on(SubNavDispatcher.OPEN_SUB_WIDE, openSubWideHandler);
      subNavDispatcher.on(SubNavDispatcher.CLOSE_SUB_WIDE, closeSubWideHandler);
      subNavDispatcher.on(SubNavDispatcher.LOGOUT, logoutHandler);
    }

    return () => {
      if (subNavDispatcher) {
        subNavDispatcher.off(SubNavDispatcher.OPEN_SUB_WIDE, openSubWideHandler);
        subNavDispatcher.off(SubNavDispatcher.CLOSE_SUB_WIDE, closeSubWideHandler);
        subNavDispatcher.off(SubNavDispatcher.LOGOUT, logoutHandler);
      }
    };
  }, [subNavDispatcher, logout]);

  return (
    <div className={stickyWrapperStyle}>
      <Sticky>
        <nav id='nav' className={style.outerWrapper}>
          {renderNavWide()}
          {renderNavNarrow()}
        </nav>
      </Sticky>
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetShowcaseCategory: (category: string) => dispatch(setShowcaseCategory(category)),
  }
}

export default connect(null, mapDispatchToProps)(Nav);