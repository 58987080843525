import { FC, useState } from "react";
import pageStyle from "../../css/pages/page.module.scss";
import { getInnerWrapperStyle, getOuterWrapperStyle } from "../../js/style-utils";
import ExpandSection from "../common/ui/ExpandSection";
import TransitionIn from "../common/ui/TransitionIn";
import Hobbies from "./about/Hobbies";
import Intro from "./about/Intro";
import Plans from "./about/Plans";
import Qualities from "./about/Qualities";
import Skills from "./about/Skills";
import Testimonials from "./about/Testimonials";

interface IProps {
  first?: boolean;
  colorIndex: number;
};

const About: FC<IProps> = ({ first = false, colorIndex }) => {
  const [skillsSelected, setSkillsSelected] = useState<boolean>(false);
  const [qualitiesSelected, setQualitiesSelected] = useState<boolean>(false);
  const outerWrapperStyle = getOuterWrapperStyle(first, colorIndex);
  const innerWrapperStyle = getInnerWrapperStyle(first);

  const onToTop = (index: number) => {
    const section = document.getElementById(`about-section-${index}`);

    if (section) {
      const bodyRect = document.body.getBoundingClientRect();
      const sectionRect = section.getBoundingClientRect();
      const offset = sectionRect.top - bodyRect.top;
      // 120px allows for the nav bar
      window.scrollTo({ top: offset - 120, behavior: 'smooth' });
    }
  };

  return (
    <>
      <a id="about" href="_"> </a>
      <div className={outerWrapperStyle}>
        <div className={pageStyle.wrapper}>
          <div className={innerWrapperStyle}>
            <TransitionIn>
              <h1>about.</h1>
            </TransitionIn>

            <Intro />

            <TransitionIn scrollPosition={0.95}>
              <ExpandSection
                index={2}
                type="about"
                startOpen={false}
                title="Qualities"
                onSelectCallback={(selected: boolean) => setQualitiesSelected(selected)}
                toTopCallback={onToTop}
              >
                <Qualities selected={qualitiesSelected} />
              </ExpandSection>
            </TransitionIn>

            <TransitionIn scrollPosition={0.95}>
              <ExpandSection
                index={1}
                type="about"
                startOpen={false}
                title="Skills"
                onSelectCallback={(selected: boolean) => setSkillsSelected(selected)}
                toTopCallback={onToTop}
              >
                <Skills selected={skillsSelected} />
              </ExpandSection>
            </TransitionIn>

            <TransitionIn scrollPosition={0.95}>
              <ExpandSection index={3} type="about" startOpen={false} title="Goals" toTopCallback={onToTop}>
                <Plans />
              </ExpandSection>
            </TransitionIn>

            <TransitionIn scrollPosition={0.95}>
              <ExpandSection index={0} type="about" startOpen={false} title="Testimonials" toTopCallback={onToTop}>
                <Testimonials />
              </ExpandSection>
            </TransitionIn>

            <TransitionIn scrollPosition={0.95}>
              <ExpandSection index={4} type="about" startOpen={false} title="Hobbies" toTopCallback={onToTop}>
                <Hobbies />
              </ExpandSection>
            </TransitionIn>

          </div>
        </div>
      </div>
    </>
  )
};

export default About;